import { faCheck, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Alert from "components/Alert";
import GlobalPagination from "components/GlobalPagination";
import Loading from "components/Loading";
import apiEndpoints from "configs/apiEndPoints";
import { FC, useEffect, useState } from "react";
import toastFunc from "utils/toast";
import { useMutationCustom, useQueryCustom } from "utils/useQueryCUstom";
import { clsx } from "clsx"; // For better class handling
import { getFileName } from "utils/getPDFName";

type Photo = {
  _id: string;
  title: string;
  path: string;
  mimetype: string;
};

type GalleryProps = {
  folderSlug?: string;
  handleClickedImage: (photo: Photo | null) => void;
  selectedPhoto: Photo | null;
  handleModal: () => void;
  editable?: boolean;
};

const limit = 20;

const Gallery: FC<GalleryProps> = ({ handleClickedImage, selectedPhoto, handleModal }) => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [page, setPage] = useState(1);
  const [preLoad, setPreLoad] = useState(true);
  const [imageLoaded, setImageLoaded] = useState<boolean[]>([]);

  const { data, isLoading, refetch } = useQueryCustom({
    name: `gallery_get`,
    url: () =>
      axios.get(apiEndpoints.files, {
        params: { page, limit },
      }),
    queryKey: ["gallery", page],
  });

  useEffect(() => {
    if (data) {
      const indexOfSelectedPhoto = data?.data?.data?.result?.findIndex(
        (image: Photo) => image._id === selectedPhoto?._id
      );
      setSelectedPhotoIndex(indexOfSelectedPhoto);
    }
  }, [data, selectedPhoto]);

  useEffect(() => {
    setTimeout(() => setPreLoad(false), 1000);
    return () => setPreLoad(true);
  }, []);

  const handleClickedImageWithIndex = (photo: Photo, index: number) => {
    handleClickedImage(photo);
    setSelectedPhotoIndex(index);
    setIsEditMode(false);
  };

  const handleCancelSelectedImage = () => {
    handleClickedImage(null);
    setSelectedPhotoIndex(null);
  };

  const { mutate: deletePhoto } = useMutationCustom({
    name: "gallery_changeStatus",
    url: async (id: string) => await axios.delete(`${apiEndpoints.files}/${id}`),
    onSuccess: () => {
      toastFunc.success({ title: "Photo deleted successfully" });
      refetch();
      handleCancelSelectedImage();
    },
    invalidQuery: ["gallery", page],
  });

  const handleDeleteButton = (photo: Photo) => {
    Alert.confirm({
      title: "Are you sure?",
      message: `You want to delete "${photo.title || photo._id}"?`,
      onConfirm: () => deletePhoto(photo._id),
    });
  };

  const count = data?.data?.data?.total || 0;
  const photos = data?.data?.data?.result || [];

  const handleImageLoad = (index: number) => {
    setImageLoaded((prev) => {
      const updatedLoaded = [...prev];
      updatedLoaded[index] = true;
      return updatedLoaded;
    });
  };

  return (
    <div className="w-full h-full">
      {preLoad || isLoading ? (
        <div className="flex justify-center items-center min-h-[300px]">
          <Loading size="w-8 h-8" />
        </div>
      ) : (
        <>
          <div className="flex justify-end items-center mb-4">
            {count > 0 && (
              <span className="text-sm text-mute">
                Showing {Math.min(page * limit, count)} of {count} entries
              </span>
            )}
          </div>
          <div className="grid grid-cols-6 gap-1 auto-rows-[200px] grid-flow-dense">
            {photos.length > 0 ? (
              photos.map((photo: Photo, index: number) => {
                return (
                  <div
                    key={photo._id}
                    className={clsx(
                      "group relative w-full h-full overflow-hidden transition-transform",
                      // selectedPhotoIndex === index ? "border-success border-2 scale-95 shadow-lg" : "hover:shadow-lg",
                      photo.mimetype.includes("image") && !imageLoaded[index] && "bg-mainSection animate-indexPuls" // Loading placeholder
                    )}
                    style={{
                      gridColumn: index % 3 === 0 ? "span 2" : "span 1", // Variable width
                      gridRow: index % 4 === 0 ? "span 2" : "span 1", // Variable height
                    }}
                  >
                    <div
                      className={`group-hover:right-0 -right-full rounded-l-md w-16 bg-danger p-2 items-center absolute top-2 flex space-x-2 transition300 z-20 gap-2`}
                    >
                      <button
                        className=" text-white flex justify-center items-center parent-animation-on-hover"
                        onClick={() => handleDeleteButton(photo)}
                        type="button"
                      >
                        <FontAwesomeIcon icon={faTrash} className="w-3 rubberBand-on-hover" />
                      </button>
                      {selectedPhotoIndex === index && (
                        <button
                          className="text-white flex justify-center items-center parent-animation-on-hover"
                          onClick={handleModal}
                          type="button"
                        >
                          <FontAwesomeIcon icon={faCheck} className="w-3 rubberBand-on-hover" />
                        </button>
                      )}
                    </div>
                    <div className="absolute bottom-2 right-2 z-10 flex space-x-2"></div>
                    {photo?.mimetype?.startsWith?.("image/") ? (
                      <img
                        src={photo.path}
                        alt="gallery item"
                        className="w-full h-full object-cover cursor-pointer"
                        onLoad={() => handleImageLoad(index)}
                        onClick={() => handleClickedImageWithIndex(photo, index)}
                      />
                    ) : photo?.mimetype?.startsWith?.("video/") ? (
                      <video
                        src={photo.path}
                        controls
                        className="w-full h-full object-cover cursor-pointer"
                        onLoad={() => handleImageLoad(index)}
                        onClick={() => handleClickedImageWithIndex(photo, index)}
                      />
                    ) : (
                      <div className="relative flex flex-col justify-end h-full">
                        <img
                          src="/images/pdf.png"
                          alt="pdf item"
                          className="w-full h-full object-cover cursor-pointer"
                          onLoad={() => handleImageLoad(index)}
                          onClick={() => handleClickedImageWithIndex(photo, index)}
                        />
                        <div className="absolute bottom-0 left-0 bg-secondaryTransparent text-black h-8 text-sm w-full p-2 flex items-center">
                          {" "}
                          {getFileName(photo.path)}{" "}
                        </div>
                      </div>
                    )}
                    <div
                      className={`${
                        selectedPhotoIndex === index ? "h-full bottom-0" : "h-0 top-0"
                      } absolute inset-x-0 w-full transition300 z-10 bg-primaryRGBA flex justify-center items-center`}
                    >
                      <div
                        className={`${
                          selectedPhotoIndex === index ? "opacity-80" : "opacity-0"
                        } w-11 h-11 rounded-full flex justify-center items-center bg-white transition300 delay-75 p-2`}
                      >
                        {/* <FontAwesomeIcon icon={faCheck} size="2xl" className="text-success" /> */}
                        <div className={`${selectedPhotoIndex === index ? "inline-flex" : "hidden"} w-11 p-2`}>
                          <svg className="animated-check" viewBox="0 0 24 24">
                            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-span-full text-center text-text">
                <h2>No Data</h2>
              </div>
            )}
          </div>
          <div className="mt-4">
            <GlobalPagination total={count} limit={limit} currentPage={page} onPageChange={setPage} />
          </div>
        </>
      )}
    </div>
  );
};

export default Gallery;
