import { faCheck, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Alert from "components/Alert";
import GlobalPagination from "components/GlobalPagination";
import Loading from "components/Loading";
import apiEndpoints from "configs/apiEndPoints";
import { FC, useEffect, useState } from "react";
import toastFunc from "utils/toast";
import { useMutationCustom, useQueryCustom } from "utils/useQueryCUstom";
import { clsx } from "clsx"; // For better class handling

type Photo = {
  _id: string;
  title: string;
  path: string;
  mimetype: string;
};
type GalleryProps = {
  folderSlug?: string;
  handleClickedImage: (photos: Photo[]) => void;
  selectedPhoto: any;
  handleModal: () => void;
  editable?: boolean;
};
const limit = 20;
const GalleryMulti: FC<GalleryProps> = ({ handleClickedImage, selectedPhoto, handleModal }) => {
  const [selectedPhotoIndices, setSelectedPhotoIndices] = useState<number[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [page, setPage] = useState(1);
  const [preLoad, setPreLoad] = useState(true);
  const [imageLoaded, setImageLoaded] = useState<boolean[]>([]);

  const { data, isLoading, refetch } = useQueryCustom({
    name: `gallery_get`,
    url: () =>
      axios.get(apiEndpoints.files, {
        params: {
          page,
          limit,
        },
      }),
    queryKey: ["gallery", page],
  });
  useEffect(() => {
    if (data) {
      const indicesOfSelectedPhotos = data?.data?.data?.result
        ?.map((image: Photo, index: number) =>
          selectedPhoto?.some((photo: any) => photo._id === image._id || photo === image?.path) ? index : -1
        )
        .filter((index: number) => index !== -1);
      setSelectedPhotoIndices(indicesOfSelectedPhotos);
    }
  }, [data, selectedPhoto]);
  useEffect(() => {
    setTimeout(() => {
      setPreLoad(false);
    }, 1000);
    return () => {
      setPreLoad(true);
    };
  }, []);
  const handleClickedImageWithIndex = (photo: Photo, index: number) => {
    console.log("🚀 ~ handleClickedImageWithIndex ~ photo:", { photo, selectedPhoto });
    const newSelectedPhotos = selectedPhoto?.includes(photo)
      ? selectedPhoto?.filter((p: any) => p._id !== photo._id)
      : selectedPhoto?.includes(photo.path)
      ? selectedPhoto?.filter((p: any) => p !== photo.path)
      : [...selectedPhoto, photo];
    handleClickedImage(newSelectedPhotos);
    const newSelectedIndices = selectedPhotoIndices?.includes(index)
      ? selectedPhotoIndices?.filter((i) => i !== index)
      : [...selectedPhotoIndices, index];
    setSelectedPhotoIndices(newSelectedIndices);
    setIsEditMode(false);
  };
  const handleCancelSelectedImages = () => {
    handleClickedImage([]);
    setSelectedPhotoIndices([]);
  };
  const { mutate: deletePhoto } = useMutationCustom({
    name: "gallery_changeStatus",
    url: async (id: string) => await axios.delete(`${apiEndpoints.files}/${id}`),
    onSuccess: () => {
      toastFunc.success({ title: "Photo deleted successfully" });
      refetch();
      // handleCancelSelectedImages();
    },
    invalidQuery: ["gallery", page],
  });
  const handleDeleteButton = (photo: Photo) => {
    Alert.confirm({
      title: "Are you sure?",
      message: `You want to delete "${photo.title || photo._id}"?`,
      onConfirm: () => deletePhoto(photo._id),
    });
  };
  const count = data?.data?.data?.total || 0;
  const photos = data?.data?.data?.result || [];

  const handleImageLoad = (index: number) => {
    setImageLoaded((prev) => {
      const updatedLoaded = [...prev];
      updatedLoaded[index] = true;
      return updatedLoaded;
    });
  };

  return (
    <div className="w-full h-full">
      {preLoad || isLoading ? (
        <div className="flex justify-center items-center min-h-[300px]">
          <Loading size="w-8 h-8" />
        </div>
      ) : (
        <>
          <div className="flex justify-end items-center mb-4">
            {count > 0 && (
              <span className="text-sm text-mute">
                Showing {Math.min(page * limit, count)} of {count} entries
              </span>
            )}
          </div>
          <div className="grid grid-cols-6 gap-1 auto-rows-[200px] grid-flow-dense">
            {photos.length > 0 ? (
              photos.map((photo: Photo, index: number) => {
                const isSelected = selectedPhotoIndices.includes(index);
                return (
                  <div
                    key={photo._id}
                    className={clsx(
                      "group relative w-full h-full overflow-hidden transition-transform",
                      // selectedPhotoIndex === index ? "border-success border-2 scale-95 shadow-lg" : "hover:shadow-lg",
                      photo.mimetype.includes("image") && !imageLoaded[index] && "bg-mainSection animate-indexPuls" // Loading placeholder
                    )}
                    style={{
                      gridColumn: index % 3 === 0 ? "span 2" : "span 1", // Variable width
                      gridRow: index % 4 === 0 ? "span 2" : "span 1", // Variable height
                    }}
                  >
                    <div
                      className={`group-hover:right-0 -right-full rounded-l-md w-16 bg-danger p-2 items-center absolute top-2 flex space-x-2 transition300 z-[15] gap-2`}
                    >
                      <button
                        className=" text-white flex justify-center items-center parent-animation-on-hover"
                        onClick={() => handleDeleteButton(photo)}
                      >
                        <FontAwesomeIcon icon={faTrash} className="w-3 rubberBand-on-hover" />
                      </button>
                      {isSelected && (
                        <button
                          className="text-white flex justify-center items-center parent-animation-on-hover"
                          onClick={handleModal}
                        >
                          <FontAwesomeIcon icon={faCheck} className="w-3 rubberBand-on-hover" />
                        </button>
                      )}
                    </div>
                    <div className="absolute bottom-2 right-2 z-10 flex space-x-2"></div>
                    {photo?.mimetype?.startsWith?.("image/") ? (
                      <img
                        src={photo.path}
                        alt="gallery item"
                        className="w-full h-full object-cover cursor-pointer"
                        onLoad={() => handleImageLoad(index)}
                        onClick={() => handleClickedImageWithIndex(photo, index)}
                      />
                    ) : photo?.mimetype?.startsWith?.("video/") ? (
                      <video
                        src={photo.path}
                        controls
                        className="w-full h-full object-cover cursor-pointer"
                        onLoad={() => handleImageLoad(index)}
                        onClick={() => handleClickedImageWithIndex(photo, index)}
                      />
                    ) : (
                      <img
                        src="/path/to/pdf-icon.png"
                        alt="pdf item"
                        className="w-full h-full object-cover cursor-pointer"
                        onLoad={() => handleImageLoad(index)}
                        onClick={() => handleClickedImageWithIndex(photo, index)}
                      />
                    )}
                    <div
                      onClick={() => handleClickedImageWithIndex(photo, index)}
                      className={`${
                        isSelected ? "h-full bottom-0" : "h-0 top-0"
                      } absolute inset-x-0 w-full transition300 z-10 bg-primaryRGBA flex justify-center items-center`}
                    >
                      <div
                        className={`${
                          isSelected ? "opacity-80" : "opacity-0"
                        } w-11 h-11 rounded-full flex justify-center items-center bg-white transition300 delay-75 p-2`}
                      >
                        {/* <FontAwesomeIcon icon={faCheck} size="2xl" className="text-success" /> */}
                        <div className={`${isSelected ? "inline-flex" : "hidden"} w-11 p-2`}>
                          <svg className="animated-check" viewBox="0 0 24 24">
                            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-span-full text-center text-text">
                <h2>No Data</h2>
              </div>
            )}
          </div>
          <div className="mt-4">
            <GlobalPagination total={count} limit={limit} currentPage={page} onPageChange={setPage} />
          </div>
        </>
      )}
    </div>
  );
};

export default GalleryMulti;
