import { arrayOfStrSchema, branchesValidation, imageSchema, stringSchema } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  careerWhatsappUrl: "careerWhatsappUrl",
  careerEmail: "careerEmail",
  mapUrl: "mapUrl",
  whatsappUrl: "whatsappUrl",
  email: "email",
  location: "location",
  phones: "phones",
  telephones: "telephones",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.careerWhatsappUrl]: stringSchema(fieldNames.careerWhatsappUrl, t),
        [fieldNames.careerEmail]: stringSchema(fieldNames.careerEmail, t),
        [fieldNames.mapUrl]: stringSchema(fieldNames.mapUrl, t),
        [fieldNames.whatsappUrl]: stringSchema(fieldNames.whatsappUrl, t),
        [fieldNames.email]: stringSchema(fieldNames.email, t),
        [fieldNames.location]: stringSchema(fieldNames.location, t),
        [fieldNames.phones]: arrayOfStrSchema(fieldNames.phones, t),
        [fieldNames.telephones]: arrayOfStrSchema(fieldNames.telephones, t),
      })
    : yup.object().shape({
        [fieldNames.careerWhatsappUrl]: stringSchema(fieldNames.careerWhatsappUrl, t),
        [fieldNames.careerEmail]: stringSchema(fieldNames.careerEmail, t),
        [fieldNames.mapUrl]: stringSchema(fieldNames.mapUrl, t),
        [fieldNames.whatsappUrl]: stringSchema(fieldNames.whatsappUrl, t),
        [fieldNames.email]: stringSchema(fieldNames.email, t),
        [fieldNames.location]: stringSchema(fieldNames.location, t),
        [fieldNames.phones]: arrayOfStrSchema(fieldNames.phones, t),
        [fieldNames.telephones]: arrayOfStrSchema(fieldNames.telephones, t),
      });
};
