import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import apiEndpoints from "configs/apiEndPoints";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toastFunc from "utils/toast";
import { useMutationCustom } from "utils/useQueryCUstom";
import DragAndDrop from "./DragAndDrop";

interface Props {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setDisableGalleryFromUploader: Dispatch<SetStateAction<boolean>>;
}
const UploadTab: React.FC<Props> = ({ setActiveTab, setDisableGalleryFromUploader }) => {
  const [userUploadedFile, setUserUploadedFile] = useState<File | null>(null);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [expanded, setExpanded] = useState<boolean>(true);
  const { t } = useTranslation();

  const handleSelectFile = (file: File) => {
    // const file = event.target.files?.[0];
    if (file) {
      setUserUploadedFile(file);
      setShowUploadDialog(true);
      setDisableGalleryFromUploader(true);
    }
  };

  const resetFields = () => {
    setUserUploadedFile(null);
    setShowUploadDialog(false);
    setUploadProgress(0);
    setExpanded(true);
  };

  const onSuccessMutating = () => {
    toastFunc.success({ title: t("success") });
    resetFields();
    setDisableGalleryFromUploader(false);
    setActiveTab("gallery");
  };

  const { mutate } = useMutationCustom({
    url: async (params: FormData) =>
      await axios.post(apiEndpoints.files, params, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 1));
          setUploadProgress(percentCompleted);
        },
      }),
    invalidQuery: ["gallery"],
    onSuccess: onSuccessMutating,
  });

  const handleUploadButton = () => {
    if (userUploadedFile) {
      const formData = new FormData();
      formData.append("file", userUploadedFile);
      mutate(formData);
    }
  };

  useEffect(() => {
    if (userUploadedFile) {
      handleUploadButton();
    }
  }, [userUploadedFile]);

  return (
    <div className="p-4">
      {/* <div className="flex items-center justify-center group">
        <label className="cursor-pointer flex items-center space-x-2 ">
          <input
            type="file"
            name="file"
            id="file"
            accept=".jpg,.jpeg,.png,.pdf,.mp4"
            className="hidden"
            onChange={handleSelectFile}
          />
          <FontAwesomeIcon icon={faPlus} className="text-2xl" />
        </label>
        <div className="ml-2">
          <span className="opacity-0 group-hover:opacity-100 transition300 text-sm">{t("upload")}</span>
          <div className="opacity-0 group-hover:opacity-100 transition300 text-[0.5rem]">{t("accepted_formats")}</div>
        </div>
      </div> */}
      <div className="mb-4">
        <h5 className="mb-0">{t("upload_your_file")}</h5>
      </div>
      <DragAndDrop handleSelectFile={handleSelectFile}>
        <input
          type="file"
          name="file"
          id="file"
          accept=".jpg,.jpeg,.png,.pdf,.mp4"
          className="hidden"
          onChange={(e) => handleSelectFile(e.target.files![0])}
        />
        <label
          htmlFor={`${showUploadDialog ? "" : "file"}`}
          className={`${
            showUploadDialog ? "cursor-not-allowed opacity-50 pointer-events-none" : " pointer-events-auto"
          } cursor-pointer text-center block px-4 py-2 rounded bg-secondary text-black`}
        >
          {t("click_to_choose")}
        </label>
        <p className="text-center mt-2">{t("or")}</p>
        <p className="text-center text-text">{t("drag_drop")}</p>
        <p className="text-xs text-center mt-2 text-text opacity-60">{t("accepted_formats")}</p>
      </DragAndDrop>
      {showUploadDialog && (
        <div
          style={{ zIndex: 200 }}
          className={`fixed bottom-4 right-4 bg-mainSection shadow-lg rounded-md p-4 flex flex-col gap-4 w-80 transition300 ${
            expanded ? "max-h-[150rem]" : "max-h-[4rem]"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-1">
              <div>
                <h3 className="font-normal">{t("uploading")}</h3>
              </div>
              <span className="text-xs font-semibold inline-block">{uploadProgress}%</span>
            </div>
            <button onClick={() => setExpanded(!expanded)}>
              <div
                className={`${
                  expanded ? "rotate-90" : "rotate-0"
                } origin-center transition300 flex justify-center items-center parent-animation-on-hover`}
              >
                <FontAwesomeIcon icon={faChevronDown} className={`w-3 h-5 ml-1 shake-on-hover`} />
              </div>
            </button>
          </div>
          <div className={`${expanded ? "opacity-100" : "opacity-0"} transition300`}>
            <div className="mt-0">
              <p className="text-sm">
                {t("uploadingSm")}: {userUploadedFile?.name}
              </p>
              <div className="relative pt-1">
                <div className="overflow-hidden h-2 text-xs flex rounded bg-primaryRGBA transition300">
                  <div
                    style={{ width: `${uploadProgress}%` }}
                    className="shadow-none flex flex-col text-center text-white whitespace-nowrap justify-center bg-primary transition300"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadTab;
