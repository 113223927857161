import { imageSchema, stringSchema } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  code: "code",
  image: "image",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.name]: stringSchema(fieldNames.name, t),
        [fieldNames.code]: stringSchema(fieldNames.code, t),
        [fieldNames.image]: imageSchema(fieldNames.image, t),
      })
    : yup.object().shape({
        [fieldNames.name]: stringSchema(fieldNames.name, t),
        [fieldNames.code]: stringSchema(fieldNames.code, t),
        [fieldNames.image]: imageSchema(fieldNames.image, t),
      });
};
