import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import TextInput from "./TextInput";
import { useTranslation } from "react-i18next";

const ArrayOfStrInput: React.FC<{ name: string; noLabel?: boolean; errorText: string }> = ({
  name,
  noLabel,
  errorText,
}) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  const { t } = useTranslation();

  const handleAddItem = (value: string) => {
    if (value.trim() !== "") {
      append({ value: value.trim() });
      //   setValue(name, [...fields.map((field: any) => field.value), value.trim()]);
      setValue(`${name}.input`, "");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent, value: string) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddItem(value);
    }
  };

  return (
    <form className="">
      <div className="flex items-center">
        <TextInput
          //   name={`${name}.input`}
          label={noLabel ? "" : name}
          isRequired
          placeholder={t("enterYourHere", { changeablePart: t(name) })}
          size="full"
          onKeyDown={(e) => handleKeyDown(e, e.currentTarget.value)}
          {...register(`${name}.input`, {
            validate: (value) => value.trim() !== "" || t(errorText),
          })}
        />
        <FontAwesomeIcon
          icon={faPlus}
          className="cursor-pointer ml-2"
          onClick={() => handleAddItem((document.querySelector(`[name="${name}.input"]`) as HTMLInputElement).value)}
        />
      </div>

      {errors[name]?.root?.message && (
        <p className="text-sm text-danger -mt-1">{t("pleaseEnter", { changeablePart: name })}</p>
      )}
      <div className="flex flex-wrap gap-2 mt-4">
        {fields.map((field: any, index: number) => {
          return (
            <div key={field.id} className="flex items-center bg-inputBg px-2 py-1 rounded">
              <span className="mr-2">{field.value}</span>
              <FontAwesomeIcon icon={faMinus} className="cursor-pointer text-danger" onClick={() => remove(index)} />
            </div>
          );
        })}
      </div>
    </form>
  );
};

export default ArrayOfStrInput;
