import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

interface TableStarsProps {
  rating: number;
}

const TableStars: FC<TableStarsProps> = ({ rating }) => {
  const totalStars = 5;

  return (
    <div className="flex">
      {[...Array(totalStars)].map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          className={`w-4 h-4 ${index < rating ? "text-yellow-500" : "text-gray-400"}`}
        />
      ))}
    </div>
  );
};

export default TableStars;
