import TableDescTruncate from "components/table/renderComponents/TableDescTruncate";
import TableImage from "components/table/renderComponents/TableImage";
import TableTruncate from "components/table/renderComponents/TableTruncate";
import { format } from "date-fns";
import { DCtableColumn } from "./DCtable";
import TableBoolean from "components/table/renderComponents/TableBoolean";
import TableVideo from "components/table/renderComponents/TableVideo";
import TableStars from "components/table/renderComponents/TableStars";

export interface Data {
  [key: string]: any;
}

export const generateColumns = (data: Data[], blacklist: string): DCtableColumn<Data>[] => {
  if (data.length === 0) return [];

  const sampleItem = data[0];
  const blacklistArray = blacklist.split(",");

  const dateColumns: DCtableColumn<Data>[] = [];

  const columns = Object.keys(sampleItem)
    .filter((key) => !blacklistArray.includes(key) && key !== "createdAt")
    .map((key) => {
      const value = sampleItem[key];
      let render;

      // Check for image files
      if (typeof value === "string" && /\.(png|jpg|jpeg)$/i.test(value)) {
        render = (item: Data) => <TableImage image={item[key]} />;

        // Check for video files
      } else if (typeof value === "string" && /\.(mp4|mpeg|mkv)$/i.test(value)) {
        render = (item: Data) => <TableVideo video={item[key]} />;

        // Check for descriptions or addresses
      } else if (typeof value === "string" && /description|shortDescription|address/i.test(key)) {
        render = (item: Data) => <TableDescTruncate description={item[key]} />;

        // Handle _id field
      } else if (key === "_id") {
        render = (item: Data) => <TableTruncate text={item[key]} withCopy />;

        // Handle stars/rating
      } else if (key === "stars") {
        render = (item: Data) => <TableStars rating={item[key]} />;

        // Handle arrays
      } else if (Array.isArray(value)) {
        render = (item: Data) =>
          item[key]?.map((element: any, index: number) => (
            <div key={index}>{typeof element === "string" ? element : JSON.stringify(element)}</div>
          )) || null;

        // Handle booleans
      } else if (typeof value === "boolean") {
        render = (item: Data) => <TableBoolean value={item[key]} />;

        // Handle date fields
      } else if (key !== "title" && typeof value === "string" && !isNaN(Date.parse(value))) {
        render = (item: Data) => format(new Date(item[key]), "yyyy-MM-dd HH:mm");
        // Add date columns to a separate array to place them before createdAt
        dateColumns.push({
          key,
          label: key,
          sortable: true,
          render,
        });
        return null;

        // TODO Handle objects
      } else if (typeof value === "object" && value !== null) {
        render = (item: Data) => <span>{JSON.stringify(item[key])}</span>;

        // Default render for other types
      } else {
        render = (item: Data) => <span>{item[key]}</span>;
      }

      return {
        key,
        label: key,
        sortable: true,
        render,
      };
    })
    .filter((column): column is any => column !== null) as DCtableColumn<Data>[];

  // Add the createdAt column at the end
  if (sampleItem.createdAt) {
    const createdAtColumn = {
      key: "createdAt",
      label: "Created At",
      sortable: true,
      render: (item: Data) => format(new Date(item["createdAt"]), "yyyy-MM-dd HH:mm"),
    };
    return [...columns, ...dateColumns, createdAtColumn];
  }

  // Add date columns right before the createdAt column
  return [...columns, ...dateColumns];
};

export {};
