import { imageMultiSchema, stringSchema, translationDescShortDesc } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  translations: "translations",
  homeImages: "homeImages",
  homeShortDescription: "homeShortDescription",
  description: "description",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.description]: stringSchema(fieldNames.description, t),
        [fieldNames.homeShortDescription]: stringSchema(fieldNames.homeShortDescription, t),
        [fieldNames.translations]: translationDescShortDesc(fieldNames.translations, t),
        [fieldNames.homeImages]: imageMultiSchema(fieldNames.homeImages, t),
      })
    : yup.object().shape({
        [fieldNames.description]: stringSchema(fieldNames.description, t),
        [fieldNames.homeShortDescription]: stringSchema(fieldNames.homeShortDescription, t),
        [fieldNames.translations]: translationDescShortDesc(fieldNames.translations, t),
        [fieldNames.homeImages]: imageMultiSchema(fieldNames.homeImages, t),
      });
};
