import { FC } from "react";

interface TableDescTruncateProps {
  description: string;
}

const TableDescTruncate: FC<TableDescTruncateProps> = ({ description }) => {
  const truncateText = (text: string, maxLines: number, maxCharsPerLine: number) => {
    const lines = text.split("\n");
    let truncated = "";
    let lineCount = 0;

    for (const line of lines) {
      if (lineCount >= maxLines) break;
      let remainingLine = line;

      while (remainingLine.length > 0 && lineCount < maxLines) {
        truncated += remainingLine.slice(0, maxCharsPerLine) + "\n";
        remainingLine = remainingLine.slice(maxCharsPerLine);
        lineCount++;
      }
    }

    return truncated.trim();
  };

  const truncatedDescription = truncateText(description, 3, 30); // Adjust maxCharsPerLine as needed

  return <div className="whitespace-pre-line">{truncatedDescription}</div>;
};

export default TableDescTruncate;
