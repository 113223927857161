import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Button from "./Button";
import { TFunction } from "i18next";

type AlertProps = {
  title: string;
  message: string;
  t?: TFunction;
  onConfirm: () => void;
};

const Alert = {
  confirm: ({ title, message, onConfirm, t }: AlertProps) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        const handleConfirm = () => {
          onConfirm();
          onClose();
        };
        return (
          <div className="flex flex-col items-center md:w-auto max-w-[300px] text-center">
            <h1 className="text-2xl text-black mb-2">{title}</h1>
            <p className="text-black">{message}</p>
            <div className="flex gap-3 mt-3">
              <Button variant="danger" onClick={onClose}>
                {t ? t("no") : "No"}
              </Button>
              <Button variant="success" onClick={handleConfirm}>
                {t ? t("yesDelete") : "Yes, Delete it"}
              </Button>
            </div>
          </div>
        );
      },
    });
  },
};

export default Alert;
