import axios from "axios";
import apiEndpoints from "configs/apiEndPoints";
import Cookies from "universal-cookie";
import toastFunc from "utils/toast";
import { create } from "zustand";
import { useAuthStore } from "./useAuthStore";
import { JWTDecoder } from "utils/others";
import { fromUnixTime } from "date-fns";

const cookies = new Cookies();

interface TokenState {
  accessToken: string | null;
  refreshToken: string | null;
  setTokens: (accessToken: string, refreshToken: string) => void;
  removeTokens: () => void;
  refreshAccessToken: () => Promise<void>;
}

export const useTokenStore = create<TokenState>((set) => ({
  accessToken: cookies.get("accessToken") || null,
  refreshToken: cookies.get("refreshToken") || null,
  setTokens: (accessToken: string, refreshToken: string) => {
    const accessTokenExpTime = accessToken ? JWTDecoder(accessToken) ?? 0 : 0;
    const refreshTokenExpTime = refreshToken ? JWTDecoder(refreshToken) ?? 0 : 0;
    // Use date-fns to convert expiry to a Date object
    const accessTokenExpiresAt = fromUnixTime(accessTokenExpTime);
    const refreshTokenExpiresAt = fromUnixTime(refreshTokenExpTime);

    // Set cookies with expiration date
    cookies.set("accessToken", accessToken, { path: "/", expires: accessTokenExpiresAt });
    cookies.set("refreshToken", refreshToken, { path: "/", expires: refreshTokenExpiresAt });
    set({ accessToken, refreshToken });
  },
  removeTokens: () => {
    cookies.remove("accessToken", { path: "/" });
    cookies.remove("refreshToken", { path: "/" });
    set({ accessToken: null, refreshToken: null });
  },
  refreshAccessToken: async () => {
    // try {
    //   const refreshToken = cookies.get("refreshToken");
    //   if (!refreshToken) throw new Error("No refresh token available");

    //   const response = await axios.post(apiEndpoints.host + apiEndpoints.refreshToken);
    //   const { accessToken, refreshToken: newRefreshToken } = response.data;
    //   cookies.set("accessToken", accessToken, { path: "/" });
    //   if (newRefreshToken) {
    //     cookies.set("refreshToken", newRefreshToken, { path: "/" });
    //     set({ refreshToken: newRefreshToken, accessToken: accessToken });
    //   }
    //   set({ accessToken });
    // } catch (error) {
    //   console.error("Failed to refresh access token:", error);
    //   toastFunc.error({ title: "Please login again" });
    //   useAuthStore.getState().logout();
    // }
    try {
      const refreshToken = cookies.get("refreshToken");
      if (!refreshToken) throw new Error("No refresh token available");

      const response = await axios.post(apiEndpoints.host + apiEndpoints.refreshToken);
      const { accessToken, refreshToken: newRefreshToken } = response.data;

      // Decode and find exp times for both tokens
      const accessTokenExpTime = accessToken ? JWTDecoder(accessToken) ?? 0 : 0;
      const refreshTokenExpTime = newRefreshToken ? JWTDecoder(newRefreshToken) ?? 0 : 0;

      // Use date-fns to convert expiry to Date objects
      const accessTokenExpiresAt = fromUnixTime(accessTokenExpTime);
      const refreshTokenExpiresAt = fromUnixTime(refreshTokenExpTime);

      // Set cookies with expiration dates based on decoded exp times
      cookies.set("accessToken", accessToken, { path: "/", expires: accessTokenExpiresAt });

      if (newRefreshToken) {
        cookies.set("refreshToken", newRefreshToken, { path: "/", expires: refreshTokenExpiresAt });
        set({ refreshToken: newRefreshToken, accessToken: accessToken });
      } else {
        set({ accessToken });
      }
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      toastFunc.error({ title: "Please login again" });
      useAuthStore.getState().logout();
      useTokenStore.getState().removeTokens();
      toastFunc.error({ title: "Unauthorized access. Please log in again." });
    }
  },
}));
