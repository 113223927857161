import TableBoolean from "components/table/renderComponents/TableBoolean";
import TableDescTruncate from "components/table/renderComponents/TableDescTruncate";
import TableImage from "components/table/renderComponents/TableImage";
import TableTruncate from "components/table/renderComponents/TableTruncate";
import { TableColumn } from "components/table/Table";

export interface Data {
  _id: string;
  name: string;
  email: string;
  phone: string;
  subject: string;
  text: string;
  isRead: boolean;
  createdAt: string;
}

export const columns: TableColumn<Data>[] = [
  {
    key: "_id",
    label: "id",
    sortable: true,
    render: (item: Data) => <TableTruncate text={item._id} withCopy />,
  },
  {
    key: "name",
    label: "name",
    sortable: true,
  },
  {
    key: "email",
    label: "email",
    sortable: true,
  },
  {
    key: "phone",
    label: "phone",
    sortable: true,
  },
  {
    key: "subject",
    label: "subject",
    sortable: true,
  },
  // {
  //   key: "product.name",
  //   label: "product",
  //   sortable: true,
  //   render: (item: any) => (item.product?.title ? item.product.title : "_"),
  // },
  {
    key: "text",
    label: "text",
    sortable: true,
    isWide: true,
    render: (item: any) => <TableDescTruncate description={item?.text} />,
  },
  {
    key: "isRead",
    label: "isRead",
    sortable: true,
    render: (item: any) => <TableBoolean value={item?.isRead} />,
  },
  {
    key: "createdAt",
    label: "createdAt",
    sortable: true,
  },
  // ----------------------- sample options with render -----------------------
  // {
  //   key: "address.city",
  //   label: "City",
  //   render: (item: any) => item.address.city, // Custom render for nested data
  // },
  // {
  //   key: "tags",
  //   label: "Tags",
  //   render: (item: any) =>
  //     item.tags.map((tag: string) => (
  //       <span key={tag} className="tag">
  //         {tag}
  //       </span>
  //     )),
  // },
];
