import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CheckboxInputProps {
  name: string;
  label?: string;
  isRequired?: boolean;
  size?: "half" | "full";
  disabled?: boolean;
}

const CheckboxInput: FC<CheckboxInputProps> = ({ name, label, isRequired, size = "half", disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-1 mb-3">
      <div className={`${size === "half" ? "md:w-1/2 w-full" : "w-full"} relative mt-1`}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <label className="flex items-center space-x-3">
              <input
                type="checkbox"
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                disabled={disabled}
                className={`transition300 bg-inputBg border rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${
                  errors[name] ? "border-danger" : "border-inputBg"
                }`}
              />
              {label && (
                <span className="text-sm font-medium text-text">
                  {t(label)}
                  {isRequired && <span className="text-danger"> *</span>}
                </span>
              )}
            </label>
          )}
        />
      </div>
      {errors[name] && <p className="text-sm text-danger -mt-1">{String(errors[name]?.message)}</p>}
    </div>
  );
};

export default CheckboxInput;
