import { FC, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import { convertToLocalString } from "utils/others";
import { useTranslation } from "react-i18next";

export interface DatePickerProps {
  name: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  darkMode?: boolean;
}

const ReusableDatePicker: FC<DatePickerProps> = ({
  name,
  label,
  placeholder = "Select a date",
  isRequired,
  darkMode = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col gap-2 mb-3 ${darkMode ? "dark" : ""}`}>
      {label && (
        <label htmlFor={name} className="text-sm font-medium text-text mb-0">
          {t(label)}
          {isRequired && <span className="text-danger"> *</span>}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          // if (field?.value) setSelectedDate(field.value);
          return (
            <div className="relative">
              <div
                onClick={() => setIsOpen(!isOpen)}
                className="parent-animation-on-hover bg-inputBg w-full px-3 py-2 border border-inputBg rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm cursor-pointer flex justify-between items-center"
              >
                <div>
                  {typeof field?.value === "string" ? (
                    field?.value
                  ) : selectedDate ? (
                    selectedDate.toLocaleDateString()
                  ) : (
                    <span className="text-gray-400">{placeholder}</span>
                  )}
                </div>
                <div>
                  <FontAwesomeIcon icon={faCalendarTimes} className="text-mute shake-on-hover" />
                </div>
              </div>
              {isOpen && (
                <div className="absolute z-10 mt-1 w-full bg-inputBg border border-inputBg rounded-md shadow-lg">
                  <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onSelect={(date) => {
                      setSelectedDate(date);
                      field.onChange(date);
                      setIsOpen(false);
                    }}
                    className={`text-center flex justify-center py-2`}
                  />
                </div>
              )}
            </div>
          );
        }}
      />
      {errors[name] && <p className="text-sm text-danger -mt-1">{String(errors[name]?.message)}</p>}
    </div>
  );
};

export default ReusableDatePicker;
