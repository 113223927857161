import TableDescTruncate from "components/table/renderComponents/TableDescTruncate";
import TableImage from "components/table/renderComponents/TableImage";
import TableTruncate from "components/table/renderComponents/TableTruncate";
import { TableColumn } from "components/table/Table";

export interface Data {
  _id: string;
  email: string;
  location: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  phones: string[];
  telephones: string[];
  createdAt: string;
}

export const columns: TableColumn<Data>[] = [
  {
    key: "_id",
    label: "id",
    sortable: true,
    render: (item: Data) => <TableTruncate text={item._id} withCopy />,
  },
  { key: "email", label: "email", sortable: true },
  {
    key: "location",
    label: "location",
    sortable: true,
    render: (item: Data) => <TableDescTruncate description={item?.location || ""} />,
  },
  { key: "linkedin", label: "linkedin", sortable: true },
  { key: "facebook", label: "facebook", sortable: true },
  { key: "instagram", label: "instagram", sortable: true },
  {
    key: "phones",
    label: "phones",
    sortable: true,
    render: (item: any) => item?.phones?.map((phone: string) => <div>{phone}</div>),
  },
  {
    key: "telephones",
    label: "telephones",
    sortable: true,
    render: (item: any) => item?.telephones?.map((telephone: string) => <div>{telephone}</div>),
  },
  { key: "createdAt", label: "createdAt", sortable: true },
  // ----------------------- sample options with render -----------------------
  // {
  //   key: "address.city",
  //   label: "City",
  //   render: (item: any) => item.address.city, // Custom render for nested data
  // },
  // {
  //   key: "tags",
  //   label: "Tags",
  //   render: (item: any) =>
  //     item.tags.map((tag: string) => (
  //       <span key={tag} className="tag">
  //         {tag}
  //       </span>
  //     )),
  // },
];
