import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import TextInput from "./TextInput";
import { useTranslation } from "react-i18next";

const DrinksInput: React.FC<{ name: string }> = ({ name }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });
  const { t } = useTranslation();

  return (
    <form className="p-4">
      <div className="flex justify-between items-center w-full mb-1 parent-animation-on-hover">
        <h2 className="text-lg font-semibold text-text">{t("drinks")}</h2>
        <FontAwesomeIcon
          icon={faPlus}
          className="squish-on-hover cursor-pointer"
          title="add"
          onClick={() => append({ title: "", subTitle: "" })}
        />
      </div>
      {fields.length
        ? fields.map((field, index) => (
            <div key={field.id} className="py-4 border-b border-border">
              <div className="flex justify-between items-center w-full mb-2 parent-animation-on-hover">
                <h3 className="text-md">{t("drinkIndex", { changeablePart: index + 1 })}</h3>
                <FontAwesomeIcon
                  title="Remove"
                  icon={faMinus}
                  className="squish-on-hover cursor-pointer text-danger"
                  onClick={() => remove(index)}
                />
              </div>
              <TextInput
                name={`${name}.${index}.title`}
                placeholder={t("enterYourHere", { changeablePart: t("title") })}
                size="full"
                isRequired
              />
              {(errors?.[name] as any)?.[index]?.title && (
                <p className="text-sm text-danger -mt-1">{String((errors?.[name] as any)?.[index]?.title?.message)}</p>
              )}
              <TextInput
                name={`${name}.${index}.subTitle`}
                placeholder={t("enterYourHere", { changeablePart: t("subTitle") })}
                size="full"
                isRequired
              />
              {(errors?.[name] as any)?.[index]?.subTitle && (
                <p className="text-sm text-danger -mt-1">
                  {String((errors?.[name] as any)?.[index]?.subTitle?.message)}
                </p>
              )}
            </div>
          ))
        : ""}
    </form>
  );
};

export default DrinksInput;
