import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { useTokenStore } from "store/useTokenStore";
import toastFunc from "utils/toast";
import apiEndpoints from "./apiEndPoints";
import { useAuthStore } from "store/useAuthStore";

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}
const configureAxios = () => {
  // Set base URL and common headers
  axios.defaults.baseURL = apiEndpoints.host;
  axios.defaults.headers.common["Accept-Language"] = "en";
  const { removeTokens } = useTokenStore.getState();
  const { logout } = useAuthStore.getState();

  // Add a request interceptor to dynamically add the Authorization header
  axios.interceptors.request.use(
    (config: CustomAxiosRequestConfig) => {
      const { accessToken, refreshToken } = useTokenStore.getState();
      if (config.url === apiEndpoints.host + apiEndpoints.refreshToken) {
        if (refreshToken) {
          config.headers["Authorization"] = `Bearer ${refreshToken}`;
        }
      } else {
        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
      }
      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      console.error({ axiosError: error });
      const originalRequest = error.config as CustomAxiosRequestConfig;
      if (originalRequest && error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const { refreshAccessToken } = useTokenStore.getState();
          await refreshAccessToken();
          const { accessToken } = useTokenStore.getState();
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      } else if (error?.response?.status === 403) {
        toastFunc.error({
          title: "Forbidden access. You do not have permission to perform this action.",
        });
      } else if (error?.response?.status === 400) {
        toastFunc.error({
          title: (error.response?.data as { message: string[] }).message[0],
        });
      } else if ((error.response?.data as { message: string })?.message) {
        toastFunc.error({ title: (error.response?.data as { message: string }).message });
      } else {
        toastFunc.error({ title: "An unexpected error occurred. Please try again later." });
      }
      return Promise.reject(error);
    }
  );
};

export default configureAxios;
