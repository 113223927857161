import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface GalleryInputMultiProps {
  description?: string;
  selectedPhoto?: any[] | string | null;
  handleModal: () => void;
  label: string;
  name: string;
  isRequired?: boolean;
  size?: "half" | "full";
}

const GalleryInputMulti: FC<GalleryInputMultiProps> = ({
  description,
  selectedPhoto = [],
  handleModal,
  label,
  name,
  isRequired,
  size = "full",
}) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const renderContent = (values: any[]) => {
    if (values?.length === 0) {
      return (
        <div className="h-64 flex items-center justify-center bg-inputBg rounded-lg">
          <div className="bg-mainSection flex items-center justify-center gap-2 p-4 rounded-lg">
            <FontAwesomeIcon icon={faUpload} className="w-5" />
            <p className="text-sm m-0 text-text">{t("click_to_upload")}</p>
          </div>
        </div>
      );
    }
    return values?.map((value, index) => {
      const isPdf = value?.mimetype?.startsWith("application/pdf");

      const isImage =
        value?.mimetype?.startsWith("image/") ||
        value?.endsWith?.(".png") ||
        value?.endsWith?.(".jpeg") ||
        value?.endsWith?.(".jpg");
      const isVideo =
        value?.mimetype?.startsWith?.("video/") ||
        value?.path?.endsWith?.(".mp4") ||
        (typeof value === "string" && value?.endsWith?.(".mp4"));
      const src = value?.path || value?.src || value;

      if (isPdf) {
        return <img key={index} src="" alt="thumbnail" className="w-24" />;
      } else if (isImage) {
        return <img key={index} src={src} alt="thumbnail" className="w-24 h-20 object-contain" />;
      } else if (isVideo) {
        return <video key={index} src={src} controls className="w-24 h-20" />;
      } else {
        return (
          <div key={index} className="h-64 flex items-center justify-center bg-inputBg rounded-lg">
            <div className="bg-mainSection flex items-center justify-center gap-2 p-4 rounded-lg">
              <FontAwesomeIcon icon={faUpload} className="w-5" />
              <p className="text-sm m-0 text-text">{t("click_to_upload")}</p>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="gallery-component mb-3">
      <div className="flex justify-between items-center mb-1">
        <label htmlFor={name} className="block text-sm font-medium text-text">
          {t(label)}
          {isRequired && <span className="text-danger"> *</span>}
          {description && <small className="text-text">{description}</small>}
        </label>
      </div>

      <Controller
        name={name}
        control={control}
        render={({ field: { value } }) => (
          <div
            onClick={handleModal}
            className={`p-2 transition300 border rounded-lg bg-inputBg hover:border-hoverPrimary cursor-pointer flex flex-wrap gap-2 justify-center items-center ${
              size === "half" ? "md:w-1/2 w-full" : "w-full"
            }
            ${errors[name] ? "border-danger" : "border-mainSection"}`}
          >
            {renderContent(value || selectedPhoto)}
          </div>
        )}
      />
      {errors[name] && <p className="text-sm text-danger ">{String(errors[name]?.message)}</p>}
    </div>
  );
};

export default GalleryInputMulti;
