import {
  detailsValidation,
  imageSchema,
  numberSchema,
  stringSchema,
  translationAcademyProgramSchema,
} from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  title: "title",
  image: "image",
  banner: "banner",
  slug: "slug",
  listOrderNumber: "listOrderNumber",
  translations: "translations",
  details: "details",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.title]: stringSchema(fieldNames.title, t),
        [fieldNames.slug]: stringSchema(fieldNames.slug, t),
        [fieldNames.translations]: translationAcademyProgramSchema(fieldNames.translations, t),
        [fieldNames.image]: imageSchema(fieldNames.image, t),
        [fieldNames.banner]: imageSchema(fieldNames.banner, t),
        [fieldNames.listOrderNumber]: numberSchema(fieldNames.listOrderNumber, t),
        [fieldNames.details]: detailsValidation(t),
      })
    : yup.object().shape({
        [fieldNames.title]: stringSchema(fieldNames.title, t),
        [fieldNames.slug]: stringSchema(fieldNames.slug, t),
        [fieldNames.translations]: translationAcademyProgramSchema(fieldNames.translations, t),
        [fieldNames.image]: imageSchema(fieldNames.image, t),
        [fieldNames.banner]: imageSchema(fieldNames.banner, t),
        [fieldNames.listOrderNumber]: numberSchema(fieldNames.listOrderNumber, t),
        [fieldNames.details]: detailsValidation(t),
      });
};
