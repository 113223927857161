import TableDescTruncate from "components/table/renderComponents/TableDescTruncate";
import TableImage from "components/table/renderComponents/TableImage";
import TableTruncate from "components/table/renderComponents/TableTruncate";
import { TableColumn } from "components/table/Table";

export interface Data {
  _id: string;
  careerWhatsappUrl: string;
  careerEmail: string;
  mapUrl: string;
  whatsappUrl: string;
  email: string;
  location: string;
  phones: string[];
  telephones: string[];
  createdAt: string;
}

export const columns: TableColumn<Data>[] = [
  {
    key: "_id",
    label: "id",
    sortable: true,
    render: (item: Data) => <TableTruncate text={item._id} withCopy />,
  },
  { key: "careerWhatsappUrl", label: "careerWhatsappUrl", sortable: true },
  { key: "careerEmail", label: "careerEmail", sortable: true },
  { key: "mapUrl", label: "mapUrl", sortable: true },
  { key: "whatsappUrl", label: "whatsappUrl", sortable: true },
  { key: "email", label: "email", sortable: true },
  { key: "location", label: "location", sortable: true },
  {
    key: "phones",
    label: "phones",
    sortable: true,
    render: (item: any) => item?.phones?.map((phone: string) => <div>{phone}</div>),
  },
  {
    key: "telephones",
    label: "telephones",
    sortable: true,
    render: (item: any) => item?.telephones?.map((telephone: string) => <div>{telephone}</div>),
  },
  { key: "createdAt", label: "createdAt", sortable: true },
];
