import { FC } from "react";
import { useTranslation } from "react-i18next";

interface LoadingProps {
  size: string;
  color?: string;
}

const Loading: FC<LoadingProps> = ({ size = "w-8 h-8", color = "primary" }) => {
  const { t } = useTranslation();
  return (
    <div className={`flex items-center justify-center ${size}`}>
      <div className={`${size} border-4 border-t-4 border-gray-200 border-t-${color} rounded-full animate-spin`}></div>
      <span className="sr-only">{t("loading")}</span>
    </div>
  );
};

export default Loading;
