import { PageConfigs } from "configs/globalInterfaces";
import { CONFIGS as USERS_CONFIGS } from "./Users/configs";
// import { CONFIGS as DASHBOARD_CONFIGS } from "./Dashboard/configs";
import { CONFIGS as ABOUT_US_CONFIGS } from "./AboutUs/configs";
import { CONFIGS as ACADEMY_INFO_CONFIGS } from "./AcademyInfo/configs";
import { CONFIGS as ACADEMY_PROGRAM_CONFIGS } from "./AcademyProgram/configs";
import { CONFIGS as BANNERS_CONFIGS } from "./Banners/configs";
import { CONFIGS as CLARIFICATION_CONFIGS } from "./Clarification/configs";
import { CONFIGS as CONTACT_US_CONFIGS } from "./ContactUs/configs";
import { CONFIGS as CONTACT_US_SETTING_CONFIGS } from "./ContactUsSetting/configs";
import { CONFIGS as FOOTER_CONFIGS } from "./Footer/configs";
import { CONFIGS as HOME_CONFIGS } from "./Home/configs";
import { CONFIGS as LANGUAGES_CONFIGS } from "./Languages/configs";
import { CONFIGS as NEWS_CONFIGS } from "./News/configs";
import { CONFIGS as OUR_VALUES_CONFIGS } from "./OurValues/configs";
import { CONFIGS as PRODUCTS_CONFIGS } from "./Products/configs";
import { CONFIGS as PRODUCTS_CATEGORY_CONFIGS } from "./ProductsCategory/configs";
import { CONFIGS as SETTING_CONFIGS } from "./Setting/configs";
import { CONFIGS as TRIALS_CONFIGS } from "./Trials/configs";

interface PagesConfig {
  [key: string]: PageConfigs;
}

export const PAGES_CONFIG: PagesConfig = {
  // dashboard: DASHBOARD_CONFIGS,
  contactUs: CONTACT_US_CONFIGS,
  contactUsSetting: CONTACT_US_SETTING_CONFIGS,
  productsCategory: PRODUCTS_CATEGORY_CONFIGS,
  products: PRODUCTS_CONFIGS,
  clarification: CLARIFICATION_CONFIGS,
  ourValues: OUR_VALUES_CONFIGS,
  news: NEWS_CONFIGS,
  banners: BANNERS_CONFIGS,
  aboutUs: ABOUT_US_CONFIGS,
  home: HOME_CONFIGS,
  academyInfo: ACADEMY_INFO_CONFIGS,
  academyProgram: ACADEMY_PROGRAM_CONFIGS,
  trials: TRIALS_CONFIGS,
  footer: FOOTER_CONFIGS,
  languages: LANGUAGES_CONFIGS,
  users: USERS_CONFIGS,
  setting: SETTING_CONFIGS,
};
