import { imageSchema, stringSchema } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  b2b: "b2b",
  allProductsBanner: "allProductsBanner",
  newsBanner: "newsBanner",
  aboutUsBanner: "aboutUsBanner",
  clarificationBanner: "clarificationBanner",
  careerBanner: "careerBanner",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.b2b]: stringSchema(fieldNames.b2b, t),
        [fieldNames.allProductsBanner]: imageSchema(fieldNames.allProductsBanner, t),
        [fieldNames.newsBanner]: imageSchema(fieldNames.newsBanner, t),
        [fieldNames.aboutUsBanner]: imageSchema(fieldNames.aboutUsBanner, t),
        [fieldNames.clarificationBanner]: imageSchema(fieldNames.clarificationBanner, t),
        [fieldNames.careerBanner]: imageSchema(fieldNames.careerBanner, t),
      })
    : yup.object().shape({
        [fieldNames.b2b]: stringSchema(fieldNames.b2b, t),
        [fieldNames.allProductsBanner]: imageSchema(fieldNames.allProductsBanner, t),
        [fieldNames.newsBanner]: imageSchema(fieldNames.newsBanner, t),
        [fieldNames.aboutUsBanner]: imageSchema(fieldNames.aboutUsBanner, t),
        [fieldNames.clarificationBanner]: imageSchema(fieldNames.clarificationBanner, t),
        [fieldNames.careerBanner]: imageSchema(fieldNames.careerBanner, t),
      });
};
