export const getFileName = (url: string): string => {
  // Extract the file name using regex to match the pattern after the numeric code and dash
  const match = url.match(/[^/]+$/);
  if (match) {
    const fullFileName = match[0];
    // Find the first occurrence of a dash and remove the preceding characters
    const actualFileName = fullFileName.replace(/^[a-zA-Z0-9-]+-/, "");
    return actualFileName;
  }
  return "";
};
