import TableImage from "components/table/renderComponents/TableImage";
import TableTruncate from "components/table/renderComponents/TableTruncate";
import { TableColumn } from "components/table/Table";

export interface Data {
  _id: string;
  name: string;
  province: string;
  district: string;
  harvestYear: string;
  position: any;
  listOrderNumber: string;
}

export const columns: TableColumn<Data>[] = [
  {
    key: "_id",
    label: "id",
    sortable: true,
    render: (item: Data) => <TableTruncate text={item._id} withCopy />,
  },
  { key: "name", label: "name", sortable: true },
  { key: "province", label: "province", sortable: true },
  { key: "district", label: "district", sortable: true },
  { key: "harvestYear", label: "harvestYear", sortable: true },
  {
    key: "position",
    label: "position",
    sortable: true,
    render: (item: Data) => <div>{`[${+item?.position[0].toFixed(3)},${+item?.position[1].toFixed(3)}]`}</div>,
  },
  { key: "listOrderNumber", label: "listOrderNumber", sortable: true },
  { key: "createdAt", label: "createdAt", sortable: true },
];
