import { confirmPasswordSchema, passwordSchema, stringSchema } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  email: "email",
  password: "password",
  confirm_password: "confirm_password",
  name: "name",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.name]: stringSchema(fieldNames.name, t),
        [fieldNames.email]: stringSchema(fieldNames.email, t),
        [fieldNames.password]: passwordSchema(fieldNames.password, t),
        [fieldNames.confirm_password]: confirmPasswordSchema(fieldNames.confirm_password, t, fieldNames.password),
      })
    : yup.object().shape({
        [fieldNames.name]: stringSchema(fieldNames.name, t),
        [fieldNames.email]: stringSchema(fieldNames.email, t),
        [fieldNames.password]: passwordSchema(fieldNames.password, t),
        [fieldNames.confirm_password]: confirmPasswordSchema(fieldNames.confirm_password, t, fieldNames.password),
      });
};
