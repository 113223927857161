import { format, fromUnixTime, subMinutes } from "date-fns";
import { FC, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "store/useAuthStore";
import { useTokenStore } from "store/useTokenStore";
import { JWTDecoder } from "utils/others";

const PrivateRoute: FC<{
  element: React.ReactElement;
}> = ({ element }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const { refreshAccessToken, accessToken } = useTokenStore();
  const expTime = accessToken ? JWTDecoder(accessToken) ?? 0 : 0;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (expTime > 0 && isAuthenticated) {
      const expirationDate = fromUnixTime(expTime);

      // Step 2: Subtract one minute from the expiration time
      const oneMinuteBeforeExpiration = subMinutes(expirationDate, 1);
      // Step 3: Calculate the milliseconds difference between the current time and one minute before expiration
      const millisecondsUntilRefetch = oneMinuteBeforeExpiration.getTime() - new Date().getTime();
      // Clear any existing timeout before setting a new one
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // If the token is already expired, navigate to login
      if (millisecondsUntilRefetch <= 0) {
        return;
      }

      // const timeoutDuration = timeUntilExpiry * 1000; // Convert to milliseconds
      // Set a timeout to refresh the access token before it expires
      timeoutRef.current = setTimeout(() => {
        refreshAccessToken();
      }, millisecondsUntilRefetch);
    }

    // Cleanup the timeout when the component unmounts
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
