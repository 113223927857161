import { imageSchema, stringSchema, translationLaTiDeSchema } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  title: "title",
  url: "url",
  description: "description",
  image: "image",
  translations: "translations",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.title]: stringSchema(fieldNames.title, t),
        [fieldNames.url]: stringSchema(fieldNames.url, t),
        [fieldNames.description]: stringSchema(fieldNames.description, t),
        [fieldNames.image]: imageSchema(fieldNames.image, t),
        [fieldNames.translations]: translationLaTiDeSchema(fieldNames.translations, t),
      })
    : yup.object().shape({
        [fieldNames.title]: stringSchema(fieldNames.title, t),
        [fieldNames.url]: stringSchema(fieldNames.url, t),
        [fieldNames.description]: stringSchema(fieldNames.description, t),
        [fieldNames.image]: imageSchema(fieldNames.image, t),
        [fieldNames.translations]: translationLaTiDeSchema(fieldNames.translations, t),
      });
};
