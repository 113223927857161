import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";

interface TextEditorInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  size?: "half" | "full";
  disabled?: boolean;
}

const TextEditorInput: FC<TextEditorInputProps> = ({
  name,
  label,
  placeholder,
  isRequired,
  size = "half",
  disabled,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-1 mb-3">
      {label && (
        <label htmlFor={name} className="text-sm font-medium text-text mb-0">
          {t(label)}
          {isRequired && <span className="text-danger"> *</span>}
        </label>
      )}
      <div className={`${size === "half" ? "md:w-1/2 w-full" : "w-full"} relative mt-1 min-h-[250px]`}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <ReactQuill
              theme="snow"
              value={field.value || ""}
              onChange={field.onChange}
              placeholder={placeholder}
              readOnly={disabled}
              className={`transition300 bg-inputBg block px-3 py-2 w-full border rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${
                errors[name] ? "border-danger" : "border-inputBg"
              }`}
            />
          )}
        />
      </div>
      {errors[name] && <p className="text-sm text-danger -mt-1">{String(errors[name]?.message)}</p>}
    </div>
  );
};

export default TextEditorInput;
