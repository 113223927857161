import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Button from "components/Button";
import Loading from "components/Loading";
import Modal from "components/Modal";
import Title from "components/Title";
import CardLayout from "components/form/CardLayout";
import FormLayout from "components/form/FormLayout";
import TwoSideLayout from "components/form/TwoSideLayout";
import GalleryInput from "components/gallery/GalleryInput";
import GalleryModal from "components/gallery/GalleryModal";
import TextInput from "components/inputs/TextInput";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import apiEndpoints from "configs/apiEndPoints";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import toastFunc from "utils/toast";
import { useMutationCustom, useQueryCustom } from "utils/useQueryCUstom";
import { CONFIGS } from "../configs";
import { fieldNames, validation } from "./validation";
import TrialsDetailsInput from "components/inputs/TrialsDetailsInput";
import TrialsDetailsInputWithoutPDF from "components/inputs/TrialsDetailsInputWithoutPDF";

interface FormValues {
  name: string;
  province: string;
  district: string;
  harvestYear: number;
  listOrderNumber: number;
  latitude?: number;
  longitude?: number;
  details?: any;
  pdf?: string | any;
}

// Custom Marker Icon
const markerIcon = new L.Icon({
  iconUrl: "/images/mapMarker.png",
  // iconSize: [25, 41],
  iconAnchor: [10, 15],
  iconSize: [16.5, 20.25],
  // iconAnchor: [9, 35],
});

const Add: FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const URL_ID = id ? id : "";
  const [editInitialLoading, setEditInitialLoading] = useState(id ? true : false);
  const { t } = useTranslation();

  const updateOrCreate = URL_ID
    ? (params: any) => axios.put(apiEndpoints[CONFIGS.apiName].base + "/" + URL_ID, params)
    : (params: any) => axios.post(apiEndpoints[CONFIGS.apiName].base, params);

  const methods = useForm<FormValues>({
    resolver: yupResolver(validation(URL_ID, t)),
  });

  // Initial data loading by ID
  const onSuccessDataById = ({ data }: any) => {
    let params = { ...data?.data };
    handleSelectPDFThumbnail(params?.pdf);
    methods.reset({
      ...params,

      latitude: params?.position?.[0],
      longitude: params?.position?.[1],
    });
    if (params.position) {
      setSelectedPosition([params.position[0], params.position[1]]);
    }
    setEditInitialLoading(false);
  };
  const { data: dataById } = useQueryCustom({
    name: `${CONFIGS.title}_getById`,
    url: () => axios.get(apiEndpoints[CONFIGS.apiName].base + "/" + URL_ID),
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  const formData = methods.getValues();
  console.log({ form: formData });
  // Mutation to create or update
  const { isLoading, mutate: submitData } = useMutationCustom({
    url: updateOrCreate,
    invalidQuery: `${CONFIGS.title}_get`,
    onSuccess: () => {
      toastFunc.success({ title: "Success" });
      navigate(CONFIGS.routes.base);
    },
  });

  // Submit handler
  const onSubmit = (data: FormValues) => {
    const requestData = {
      ...data,
      position: [data.latitude, data.longitude],
      pdf: data?.pdf?.path ? data?.pdf?.path : PDFThumbnail,
    };
    delete requestData.latitude;
    delete requestData.longitude;
    submitData(requestData);
    console.log(requestData);
  };

  // Map and coordinates handling
  const [selectedPosition, setSelectedPosition] = useState<[number, number] | null>(null);

  // LocationMarker component for map click interaction
  const LocationMarker = () => {
    useMapEvents({
      click(e: any) {
        const { lat, lng } = e.latlng;
        setSelectedPosition([lat, lng]);
        methods.setValue("latitude", lat);
        methods.setValue("longitude", lng);
      },
    });
    return selectedPosition ? <Marker position={selectedPosition} icon={markerIcon} /> : null;
  };

  // -------------------------------- PDF ----------------------------------
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [PDFThumbnail, setPDFThumbnail] = useState<
    | {
        mimetype: string;
        src: string;
      }
    | string
    | null
  >(null);

  const handlePDFModal = () => {
    setShowPDFModal(!showPDFModal);
  };

  const handleSelectPDFThumbnail = (thumbnail: { mimetype: string; src: string } | string) => {
    setPDFThumbnail(thumbnail);
    methods.setValue(fieldNames.pdf, thumbnail);
    methods.clearErrors(fieldNames.pdf);
  };

  return (
    <div>
      {editInitialLoading && (
        <Modal isOpen={true} title={t("loadingData")}>
          <div className="flex justify-center items-center">
            <Loading size="w-8 h-8" />
          </div>
        </Modal>
      )}
      <Title
        hasBtn
        btnTitle={t("backTitle", { changeablePart: t(CONFIGS.title) })}
        title={
          URL_ID
            ? t("editTitle", { changeablePart: t(CONFIGS.title) })
            : t("addNew", { changeablePart: t(CONFIGS.title) })
        }
        url={CONFIGS.routes.base + CONFIGS.routes.list}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4">
          <FormLayout
            main={
              <>
                <CardLayout>
                  <TwoSideLayout
                    left={
                      <>
                        <TextInput
                          isRequired
                          name={fieldNames.name}
                          placeholder={t("enterYourHere", { changeablePart: t(fieldNames.name) })}
                          label={fieldNames.name}
                          size="full"
                        />
                        <TextInput
                          isRequired
                          name={fieldNames.province}
                          placeholder={t("enterYourHere", { changeablePart: t(fieldNames.province) })}
                          label={fieldNames.province}
                          size="full"
                        />
                        <TextInput
                          isRequired
                          name={fieldNames.district}
                          placeholder={t("enterYourHere", { changeablePart: t(fieldNames.district) })}
                          label={fieldNames.district}
                          size="full"
                        />
                        <TextInput
                          isRequired
                          name={fieldNames.harvestYear}
                          placeholder={t("enterYourHere", { changeablePart: t(fieldNames.harvestYear) })}
                          label={fieldNames.harvestYear}
                          size="full"
                          type="number"
                        />
                        <TextInput
                          isRequired
                          name={fieldNames.listOrderNumber}
                          placeholder={t("enterYourHere", { changeablePart: t(fieldNames.listOrderNumber) })}
                          type="number"
                          label={fieldNames.listOrderNumber}
                          size="full"
                        />
                      </>
                    }
                    right={
                      <>
                        <GalleryInput
                          isRequired
                          handleModal={handlePDFModal}
                          selectedPhoto={PDFThumbnail}
                          label={fieldNames.pdf}
                          name={fieldNames.pdf}
                          size="full"
                        />
                      </>
                    }
                  />
                </CardLayout>

                {/* Map Section */}
                <CardLayout head={{ title: t("map") }}>
                  <h2 className="text-lg font-semibold mb-2">{t("selectOnMap")}</h2>
                  <MapContainer
                    center={[39.9334, 32.8597]}
                    zoom={6}
                    style={{ height: "400px", width: "100%", zIndex: 5 }}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <LocationMarker />
                  </MapContainer>

                  {/* Latitude and Longitude Inputs */}
                  <div className="flex space-x-4 mt-4">
                    <TextInput
                      name="latitude"
                      label={t("latitude")}
                      isRequired
                      placeholder={t("latitude")}
                      size="half"
                      value={methods.getValues("latitude")?.toString()}
                      disabled
                    />
                    <TextInput
                      name="longitude"
                      label={t("longitude")}
                      isRequired
                      placeholder={t("longitude")}
                      size="half"
                      value={methods.getValues("longitude")?.toString()}
                      disabled
                    />
                  </div>
                </CardLayout>

                <CardLayout head={{ title: t("tableDetails") }}>
                  <TrialsDetailsInputWithoutPDF name={fieldNames.details} />
                </CardLayout>
              </>
            }
            side={
              <CardLayout head={{ title: "action" }} className="sticky top-4">
                <Button type="submit" variant="success" isFullSize loading={isLoading}>
                  {t("submit")}
                </Button>
              </CardLayout>
            }
          />
        </form>
      </FormProvider>
      <GalleryModal
        showModal={showPDFModal}
        handleModal={handlePDFModal}
        handleClickedImage={handleSelectPDFThumbnail}
        selectedPhoto={PDFThumbnail}
        FOLDER_SLUG="your-folder-slug"
      />
    </div>
  );
};

export default Add;
