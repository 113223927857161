import { FC } from "react";

interface TableImageProps {
  image: string;
  isCircle?: boolean;
}

const TableImage: FC<TableImageProps> = ({ image, isCircle }) => {
  return (
    <img
      src={image}
      className={`${isCircle ? "w-10 h-10 rounded-full" : "w-20 h-12 rounded-md"} object-cover object-top`}
    />
  );
};

export default TableImage;
