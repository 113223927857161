import { numberSchema, passwordSchema, stringSchema, translationTitle } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  email: "email",
  password: "password",
} as const;

export const validation = (t: TFunction) => {
  return yup.object().shape({
    [fieldNames.email]: stringSchema(fieldNames.email, t),
    [fieldNames.password]: passwordSchema(fieldNames.password, t),
  });
};
