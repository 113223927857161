import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Button from "components/Button";
import Loading from "components/Loading";
import Modal from "components/Modal";
import Title from "components/Title";
import CardLayout from "components/form/CardLayout";
import FormLayout from "components/form/FormLayout";
import TwoSideLayout from "components/form/TwoSideLayout";
import GalleryInputMulti from "components/gallery/GalleryInputMulti";
import GalleryModal from "components/gallery/GalleryModal";
import TextEditorInput from "components/inputs/TextEditorInput";
import TextInput from "components/inputs/TextInput";
import TranslationDescShortDescInput from "components/inputs/TranslationDescShortDescInput";
import apiEndpoints from "configs/apiEndPoints";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import toastFunc from "utils/toast";
import { useMutationCustom, useQueryCustom } from "utils/useQueryCUstom";
import { CONFIGS } from "../configs";
import { fieldNames, validation } from "./validation";

interface Translation {
  description: string;
  language: any;
  homeShortDescription: string;
}

interface FormValues {
  description: string;
  homeShortDescription: string;
  homeImages?: string | any;
  translations?: Translation[];
}

const Add: FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const URL_ID = id ? id : "";
  const [editInitialLoading, setEditInitialLoading] = useState(id ? true : false);
  const { t } = useTranslation();

  const updateOrCreate = URL_ID
    ? (params: any) => axios.put(apiEndpoints[CONFIGS.apiName].base + "/" + URL_ID, params)
    : (params: any) => axios.post(apiEndpoints[CONFIGS.apiName].base, params);

  const methods = useForm<FormValues>({
    resolver: yupResolver(validation(URL_ID, t)),
  });

  // --------------------------------------------------- data by id query
  const onSuccessDataById = ({ data }: any) => {
    let params = { ...data?.data };
    handleSelectHomeImagesThumbnail(params?.homeImages);
    const convertedTranslation = params?.translations?.map((item: any) => ({
      ...item,
      language: { value: item.language.code, label: item.language.name },
    }));
    methods.reset({
      ...params,
      translations: convertedTranslation,
    });
    setEditInitialLoading(false);
  };
  const { data: dataById } = useQueryCustom({
    name: `${CONFIGS.title}_getById`,
    url: () => axios.get(apiEndpoints[CONFIGS.apiName].base + "/" + URL_ID),
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });
  // --------------------------------------------------- Create or update mutation (base on URL_ID)
  const { isLoading, mutate: submitData } = useMutationCustom({
    url: updateOrCreate,
    invalidQuery: `${CONFIGS.title}_get`,
    onSuccess: () => {
      toastFunc.success({ title: "Success" });
      navigate(CONFIGS.routes.base);
    },
  });

  // -------------------------------------------- onSubmit
  const onSubmit = (data: FormValues) => {
    const convertedTranslation = data?.translations?.map((item) => ({
      ...item,
      language: item.language.value,
    }));

    const homeImagesPaths = data?.homeImages?.map((item: any) => (item.path ? item.path : item));

    const requestData = {
      ...data,
      homeImages: homeImagesPaths || homeImagesThumbnail,
      translations: convertedTranslation,
    };
    console.log(requestData);
    submitData(requestData);
  };

  //-------------------------------- homeImages ----------------------------------
  const [showHomeImagesModal, setShowHomeImagesModal] = useState(false);
  const [homeImagesThumbnail, setHomeImagesThumbnail] = useState<any[] | string | null>([]);

  const handleHomeImagesModal = () => {
    setShowHomeImagesModal(!showHomeImagesModal);
  };

  const handleSelectHomeImagesThumbnail = (thumbnail: any[] | string) => {
    setHomeImagesThumbnail(thumbnail);
    methods.setValue(fieldNames.homeImages, thumbnail);
    methods.clearErrors(fieldNames.homeImages);
  };
  return (
    <div>
      {editInitialLoading && (
        <Modal isOpen={true} title={t("loadingData")}>
          <div className="flex justify-center items-center">
            <Loading size="w-8 h-8" />
          </div>
        </Modal>
      )}
      <Title
        hasBtn
        btnTitle={t("backTitle", { changeablePart: t(CONFIGS.title) })}
        title={
          URL_ID
            ? t("editTitle", { changeablePart: t(CONFIGS.title) })
            : t("addNew", { changeablePart: t(CONFIGS.title) })
        }
        url={CONFIGS.routes.base + CONFIGS.routes.list}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4">
          <FormLayout
            main={
              <>
                <CardLayout>
                  <TwoSideLayout
                    left={
                      <>
                        <TextEditorInput
                          isRequired
                          name={fieldNames.description}
                          placeholder={t("enterYourHere", { changeablePart: t(fieldNames.description) })}
                          label={fieldNames.description}
                          size="full"
                        />
                        <TextEditorInput
                          isRequired
                          name={fieldNames.homeShortDescription}
                          placeholder={t("enterYourHere", { changeablePart: t(fieldNames.homeShortDescription) })}
                          label={fieldNames.homeShortDescription}
                          size="full"
                        />
                      </>
                    }
                    right={
                      <>
                        <GalleryInputMulti
                          isRequired
                          handleModal={handleHomeImagesModal}
                          selectedPhoto={homeImagesThumbnail}
                          label={fieldNames.homeImages}
                          name={fieldNames.homeImages}
                          size="full"
                        />
                      </>
                    }
                  />
                </CardLayout>
                <CardLayout head={{ noTitle: true }}>
                  <TranslationDescShortDescInput name={fieldNames.translations} />
                </CardLayout>
              </>
            }
            side={
              <CardLayout head={{ title: "action" }} className="sticky top-4">
                <Button type="submit" variant="success" isFullSize loading={isLoading}>
                  {t("submit")}
                </Button>
              </CardLayout>
            }
          />
        </form>
      </FormProvider>
      <GalleryModal
        isMulti
        showModal={showHomeImagesModal}
        handleModal={handleHomeImagesModal}
        handleClickedImage={handleSelectHomeImagesThumbnail}
        selectedPhoto={homeImagesThumbnail}
        FOLDER_SLUG="your-folder-slug"
      />
    </div>
  );
};

export default Add;
