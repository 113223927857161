import AboutUs from "pages/AboutUs";
import AcademyInfo from "pages/AcademyInfo";
import AcademyProgram from "pages/AcademyProgram";
import Banners from "pages/Banners";
import Clarification from "pages/Clarification";
import ContactUs from "pages/ContactUs";
import ContactUsSetting from "pages/ContactUsSetting";
import Footer from "pages/Footer";
import Home from "pages/Home";
import Languages from "pages/Languages";
import Login from "pages/Login/Login";
import News from "pages/News";
import OurValues from "pages/OurValues";
import Products from "pages/Products";
import ProductsCategory from "pages/ProductsCategory";
import Setting from "pages/Setting";
import Users from "pages/Users";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { routeVariables } from "./routeVariables";
import Trials from "pages/Trials";

interface RouteConfig {
  path: string;
  element: ReactElement;
  requiresAuth?: boolean;
  exact?: boolean;
}

export const routesConfig: RouteConfig[] = [
  { path: "/", element: <Navigate to={routeVariables.main} /> },
  { path: routeVariables.login, element: <Login /> },
  // { path: routeVariables.dashboard.base, element: <div>dashboard</div>, requiresAuth: true },
  { path: routeVariables.users.all, element: <Users />, requiresAuth: true },
  { path: routeVariables.news.all, element: <News />, requiresAuth: true },
  { path: routeVariables.languages.all, element: <Languages />, requiresAuth: true },
  { path: routeVariables.aboutUs.all, element: <AboutUs />, requiresAuth: true },
  { path: routeVariables.banners.all, element: <Banners />, requiresAuth: true },
  { path: routeVariables.contactUs.all, element: <ContactUs />, requiresAuth: true },
  { path: routeVariables.products.all, element: <Products />, requiresAuth: true },
  { path: routeVariables.academyInfo.all, element: <AcademyInfo />, requiresAuth: true },
  { path: routeVariables.academyProgram.all, element: <AcademyProgram />, requiresAuth: true },
  { path: routeVariables.productsCategory.all, element: <ProductsCategory />, requiresAuth: true },
  { path: routeVariables.home.all, element: <Home />, requiresAuth: true },
  { path: routeVariables.footer.all, element: <Footer />, requiresAuth: true },
  { path: routeVariables.contactUsSetting.all, element: <ContactUsSetting />, requiresAuth: true },
  { path: routeVariables.setting.all, element: <Setting />, requiresAuth: true },
  { path: routeVariables.clarification.all, element: <Clarification />, requiresAuth: true },
  { path: routeVariables.ourValues.all, element: <OurValues />, requiresAuth: true },
  { path: routeVariables.trials.all, element: <Trials />, requiresAuth: true },
];
