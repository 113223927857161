import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Button from "components/Button";
import Loading from "components/Loading";
import Modal from "components/Modal";
import Title from "components/Title";
import CardLayout from "components/form/CardLayout";
import FormLayout from "components/form/FormLayout";
import TextInput from "components/inputs/TextInput";
import apiEndpoints from "configs/apiEndPoints";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import toastFunc from "utils/toast";
import { useMutationCustom, useQueryCustom } from "utils/useQueryCUstom";
import { CONFIGS } from "../configs";
import { fieldNames, validation } from "./validation";
import GalleryInput from "components/gallery/GalleryInput";
import GalleryModal from "components/gallery/GalleryModal";

interface FormValues {
  b2b: string;
  allProductsBanner?: string | any;
  newsBanner?: string | any;
  aboutUsBanner?: string | any;
  clarificationBanner?: string | any;
  careerBanner?: string | any;
}

const Add: FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const URL_ID = id ? id : "";
  const [editInitialLoading, setEditInitialLoading] = useState(id ? true : false);
  const { t } = useTranslation();

  const updateOrCreate = URL_ID
    ? (params: any) => axios.put(apiEndpoints[CONFIGS.apiName].base + "/" + URL_ID, params)
    : (params: any) => axios.post(apiEndpoints[CONFIGS.apiName].base, params);

  const methods = useForm<FormValues>({
    resolver: yupResolver(validation(URL_ID, t)),
  });

  // --------------------------------------------------- data by id query
  const onSuccessDataById = ({ data }: any) => {
    let params = { ...data?.data };
    handleSelectAllProductsBannerThumbnail(params?.allProductsBanner);
    handleSelectNewsBannerThumbnail(params?.newsBanner);
    handleSelectAboutUsBannerThumbnail(params?.aboutUsBanner);
    handleSelectClarificationBannerThumbnail(params?.clarificationBanner);
    handleSelectCareerBannerThumbnail(params?.careerBanner);
    methods.reset({
      ...params,
    });
    setEditInitialLoading(false);
  };
  const { data: dataById } = useQueryCustom({
    name: `${CONFIGS.title}_getById`,
    url: () => axios.get(apiEndpoints[CONFIGS.apiName].base + "/" + URL_ID),
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });
  // --------------------------------------------------- Create or update mutation (base on URL_ID)
  const { isLoading, mutate: submitData } = useMutationCustom({
    url: updateOrCreate,
    invalidQuery: `${CONFIGS.title}_get`,
    onSuccess: () => {
      toastFunc.success({ title: "Success" });
      navigate(CONFIGS.routes.base);
    },
  });

  // -------------------------------------------- onSubmit
  const onSubmit = (data: FormValues) => {
    const requestData = {
      b2b: data.b2b,
      allProductsBanner: data?.allProductsBanner?.path ? data?.allProductsBanner?.path : allProductsBannerThumbnail,
      newsBanner: data?.newsBanner?.path ? data?.newsBanner?.path : newsBannerThumbnail,
      aboutUsBanner: data?.aboutUsBanner?.path ? data?.aboutUsBanner?.path : aboutUsBannerThumbnail,
      clarificationBanner: data?.clarificationBanner?.path
        ? data?.clarificationBanner?.path
        : clarificationBannerThumbnail,
      careerBanner: data?.careerBanner?.path ? data?.careerBanner?.path : careerBannerThumbnail,
    };
    console.log(requestData);
    submitData(requestData);
  };

  // -------------------------------- AllProductsBanner ----------------------------------
  const [showAllProductsBannerModal, setShowAllProductsBannerModal] = useState(false);
  const [allProductsBannerThumbnail, setAllProductsBannerThumbnail] = useState<
    | {
        mimetype: string;
        src: string;
      }
    | string
    | null
  >(null);

  const handleAllProductsBannerModal = () => {
    setShowAllProductsBannerModal(!showAllProductsBannerModal);
  };

  const handleSelectAllProductsBannerThumbnail = (thumbnail: { mimetype: string; src: string } | string) => {
    setAllProductsBannerThumbnail(thumbnail);
    methods.setValue(fieldNames.allProductsBanner, thumbnail);
    methods.clearErrors(fieldNames.allProductsBanner);
  };
  // -------------------------------- AboutUsBanner ----------------------------------
  const [showAboutUsBannerModal, setShowAboutUsBannerModal] = useState(false);
  const [aboutUsBannerThumbnail, setAboutUsBannerThumbnail] = useState<
    | {
        mimetype: string;
        src: string;
      }
    | string
    | null
  >(null);

  const handleAboutUsBannerModal = () => {
    setShowAboutUsBannerModal(!showAboutUsBannerModal);
  };

  const handleSelectAboutUsBannerThumbnail = (thumbnail: { mimetype: string; src: string } | string) => {
    setAboutUsBannerThumbnail(thumbnail);
    methods.setValue(fieldNames.aboutUsBanner, thumbnail);
    methods.clearErrors(fieldNames.aboutUsBanner);
  };
  // -------------------------------- ClarificationBanner ----------------------------------
  const [showClarificationBannerModal, setShowClarificationBannerModal] = useState(false);
  const [clarificationBannerThumbnail, setClarificationBannerThumbnail] = useState<
    | {
        mimetype: string;
        src: string;
      }
    | string
    | null
  >(null);

  const handleClarificationBannerModal = () => {
    setShowClarificationBannerModal(!showClarificationBannerModal);
  };

  const handleSelectClarificationBannerThumbnail = (thumbnail: { mimetype: string; src: string } | string) => {
    setClarificationBannerThumbnail(thumbnail);
    methods.setValue(fieldNames.clarificationBanner, thumbnail);
    methods.clearErrors(fieldNames.clarificationBanner);
  };
  // -------------------------------- NewsBanner ----------------------------------
  const [showNewsBannerModal, setShowNewsBannerModal] = useState(false);
  const [newsBannerThumbnail, setNewsBannerThumbnail] = useState<
    | {
        mimetype: string;
        src: string;
      }
    | string
    | null
  >(null);

  const handleNewsBannerModal = () => {
    setShowNewsBannerModal(!showNewsBannerModal);
  };

  const handleSelectNewsBannerThumbnail = (thumbnail: { mimetype: string; src: string } | string) => {
    setNewsBannerThumbnail(thumbnail);
    methods.setValue(fieldNames.newsBanner, thumbnail);
    methods.clearErrors(fieldNames.newsBanner);
  };
  // -------------------------------- CareerBanner ----------------------------------
  const [showCareerBannerModal, setShowCareerBannerModal] = useState(false);
  const [careerBannerThumbnail, setCareerBannerThumbnail] = useState<
    | {
        mimetype: string;
        src: string;
      }
    | string
    | null
  >(null);

  const handleCareerBannerModal = () => {
    setShowCareerBannerModal(!showCareerBannerModal);
  };

  const handleSelectCareerBannerThumbnail = (thumbnail: { mimetype: string; src: string } | string) => {
    setCareerBannerThumbnail(thumbnail);
    methods.setValue(fieldNames.careerBanner, thumbnail);
    methods.clearErrors(fieldNames.careerBanner);
  };

  return (
    <div>
      {editInitialLoading && (
        <Modal isOpen={true} title={t("loadingData")}>
          <div className="flex justify-center items-center">
            <Loading size="w-8 h-8" />
          </div>
        </Modal>
      )}
      <Title
        hasBtn
        btnTitle={t("backTitle", { changeablePart: t(CONFIGS.title) })}
        title={
          URL_ID
            ? t("editTitle", { changeablePart: t(CONFIGS.title) })
            : t("addNew", { changeablePart: t(CONFIGS.title) })
        }
        url={CONFIGS.routes.base + CONFIGS.routes.list}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4">
          <FormLayout
            main={
              <>
                <CardLayout>
                  <TextInput
                    isRequired
                    name={fieldNames.b2b}
                    placeholder={t("enterYourHere", { changeablePart: t(fieldNames.b2b) })}
                    label={fieldNames.b2b}
                    size="full"
                  />
                  <GalleryInput
                    isRequired
                    handleModal={handleAllProductsBannerModal}
                    selectedPhoto={allProductsBannerThumbnail}
                    label={fieldNames.allProductsBanner}
                    name={fieldNames.allProductsBanner}
                    size="full"
                  />
                  <GalleryInput
                    isRequired
                    handleModal={handleNewsBannerModal}
                    selectedPhoto={newsBannerThumbnail}
                    label={fieldNames.newsBanner}
                    name={fieldNames.newsBanner}
                    size="full"
                  />
                  <GalleryInput
                    isRequired
                    handleModal={handleAboutUsBannerModal}
                    selectedPhoto={aboutUsBannerThumbnail}
                    label={fieldNames.aboutUsBanner}
                    name={fieldNames.aboutUsBanner}
                    size="full"
                  />
                  <GalleryInput
                    isRequired
                    handleModal={handleClarificationBannerModal}
                    selectedPhoto={clarificationBannerThumbnail}
                    label={fieldNames.clarificationBanner}
                    name={fieldNames.clarificationBanner}
                    size="full"
                  />
                  <GalleryInput
                    isRequired
                    handleModal={handleCareerBannerModal}
                    selectedPhoto={careerBannerThumbnail}
                    label={fieldNames.careerBanner}
                    name={fieldNames.careerBanner}
                    size="full"
                  />
                </CardLayout>
              </>
            }
            side={
              <CardLayout head={{ title: "action" }} className="sticky top-4">
                <Button type="submit" variant="success" isFullSize loading={isLoading}>
                  {t("submit")}
                </Button>
              </CardLayout>
            }
          />
        </form>
      </FormProvider>
      <GalleryModal
        showModal={showAllProductsBannerModal}
        handleModal={handleAllProductsBannerModal}
        handleClickedImage={handleSelectAllProductsBannerThumbnail}
        selectedPhoto={allProductsBannerThumbnail}
        FOLDER_SLUG="your-folder-slug"
      />
      <GalleryModal
        showModal={showNewsBannerModal}
        handleModal={handleNewsBannerModal}
        handleClickedImage={handleSelectNewsBannerThumbnail}
        selectedPhoto={newsBannerThumbnail}
        FOLDER_SLUG="your-folder-slug"
      />
      <GalleryModal
        showModal={showAboutUsBannerModal}
        handleModal={handleAboutUsBannerModal}
        handleClickedImage={handleSelectAboutUsBannerThumbnail}
        selectedPhoto={aboutUsBannerThumbnail}
        FOLDER_SLUG="your-folder-slug"
      />
      <GalleryModal
        showModal={showClarificationBannerModal}
        handleModal={handleClarificationBannerModal}
        handleClickedImage={handleSelectClarificationBannerThumbnail}
        selectedPhoto={clarificationBannerThumbnail}
        FOLDER_SLUG="your-folder-slug"
      />
      <GalleryModal
        showModal={showCareerBannerModal}
        handleModal={handleCareerBannerModal}
        handleClickedImage={handleSelectCareerBannerThumbnail}
        selectedPhoto={careerBannerThumbnail}
        FOLDER_SLUG="your-folder-slug"
      />
    </div>
  );
};

export default Add;
