import TableDescTruncate from "components/table/renderComponents/TableDescTruncate";
import TableImage from "components/table/renderComponents/TableImage";
import TableStars from "components/table/renderComponents/TableStars";
import TableTruncate from "components/table/renderComponents/TableTruncate";
import { TableColumn } from "components/table/Table";
import { format } from "date-fns";

export interface Data {
  _id: string;
  image: string;
  banner: string;
  title: string;
  description: string;
  shortDescription: string;
  howToUse: string;
  slug: string;
  listOrderNumber: string;

  createdAt: string;
}

export const columns: TableColumn<Data>[] = [
  {
    key: "_id",
    label: "id",
    sortable: true,
    render: (item: Data) => <TableTruncate text={item._id} withCopy />,
  },
  {
    key: "image",
    label: "image",
    sortable: true,
    render: (item: Data) => <TableImage image={item.image} />,
  },
  {
    key: "banner",
    label: "banner",
    sortable: true,
    render: (item: Data) => <TableImage image={item.banner} />,
  },
  { key: "title", label: "title", sortable: true },
  {
    key: "description",
    label: "description",
    sortable: true,
    render: (item: any) => <TableDescTruncate description={item?.description} />,
  },
  { key: "slug", label: "slug", sortable: true },
  {
    key: "shortDescription",
    label: "shortDescription",
    sortable: true,
    render: (item: any) => <TableDescTruncate description={item?.shortDescription} />,
  },
  { key: "listOrderNumber", label: "listOrderNumber", sortable: true },
  {
    key: "howToUse",
    label: "howToUse",
    sortable: true,
    isWide: true,
    render: (item: any) => <TableDescTruncate description={item?.howToUse} />,
  },
  { key: "createdAt", label: "createdAt", sortable: true },
];
