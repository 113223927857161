import {
  imageSchema,
  numberSchema,
  stringSchema,
  trialsDetailsWithoutPDFValidation,
} from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  province: "province",
  district: "district",
  harvestYear: "harvestYear",
  listOrderNumber: "listOrderNumber",
  latitude: "latitude",
  longitude: "longitude",
  details: "details",
  pdf: "pdf",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.name]: stringSchema(fieldNames.name, t),
        [fieldNames.province]: stringSchema(fieldNames.province, t),
        [fieldNames.district]: stringSchema(fieldNames.district, t),
        [fieldNames.harvestYear]: numberSchema(fieldNames.harvestYear, t),
        [fieldNames.listOrderNumber]: numberSchema(fieldNames.listOrderNumber, t),
        [fieldNames.pdf]: imageSchema(fieldNames.pdf, t),
        [fieldNames.details]: trialsDetailsWithoutPDFValidation(t),
      })
    : yup.object().shape({
        [fieldNames.name]: stringSchema(fieldNames.name, t),
        [fieldNames.province]: stringSchema(fieldNames.province, t),
        [fieldNames.district]: stringSchema(fieldNames.district, t),
        [fieldNames.harvestYear]: numberSchema(fieldNames.harvestYear, t),
        [fieldNames.pdf]: imageSchema(fieldNames.pdf, t),

        [fieldNames.listOrderNumber]: numberSchema(fieldNames.listOrderNumber, t),
        [fieldNames.details]: trialsDetailsWithoutPDFValidation(t),
      });
};
