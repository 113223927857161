import { faChevronDown, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "boring-avatars";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "store/useAuthStore";
import { getInitials } from "utils/others";

const UserDropDown: FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const logOut = useAuthStore((state) => state.logout);
  const userEmail = useAuthStore((state) => state.userEmail);

  return (
    <div className="">
      <div
        className="flex items-center gap-2 cursor-pointer parent-animation-on-hover"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="w-10 h-10 flex items-center justify-center rounded-full bg-primary text-white ">
          {/* {userEmail ? getInitials(userEmail) : ""} */}
          {userEmail ? (
            <Avatar name={userEmail} colors={["#82c6ff", "#c9f08f", "#f88156", "#f23460", "#89bf42"]} variant="beam" />
          ) : (
            ""
          )}
        </div>
        <div
          className={`${
            isOpen ? "rotate-90" : "rotate-0"
          } origin-center transition300 flex justify-center items-center`}
        >
          <FontAwesomeIcon icon={faChevronDown} className={`w-2 h-3 ml-1 shake-on-hover`} />
        </div>
      </div>
      {isOpen && (
        <div className="rounded-bl-md rounded-br-md w-fit absolute right-0 pt-5 pb-4 pr-2 bg-sidebar text-text">
          <div className="flex flex-col p-1 pl-3 pb-2">
            {/* <p className="text-sm">Mathew</p> */}
            <em className="text-xs">{userEmail || ""}</em>
          </div>
          <div className="p-1 pl-3 flex gap-3 cursor-pointer items-center" onClick={logOut}>
            <FontAwesomeIcon icon={faSignOut} />
            {t("signOut")}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropDown;
