import {
  booleanSchema,
  imageMultiSchema,
  imageSchema,
  numberSchema,
  stringSchema,
  translationBrandText,
  translationLaTiDeSchema,
} from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  email: "email",
  phone: "phone",
  subject: "subject",
  text: "text",
  isRead: "isRead",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.name]: stringSchema(fieldNames.name, t),
        [fieldNames.email]: stringSchema(fieldNames.email, t),
        [fieldNames.phone]: stringSchema(fieldNames.phone, t),
        [fieldNames.subject]: stringSchema(fieldNames.subject, t),
        [fieldNames.text]: stringSchema(fieldNames.text, t),
        [fieldNames.isRead]: booleanSchema(fieldNames.isRead, t),
      })
    : yup.object().shape({
        [fieldNames.name]: stringSchema(fieldNames.name, t),
        [fieldNames.email]: stringSchema(fieldNames.email, t),
        [fieldNames.phone]: stringSchema(fieldNames.phone, t),
        [fieldNames.subject]: stringSchema(fieldNames.subject, t),
        [fieldNames.text]: stringSchema(fieldNames.text, t),
        [fieldNames.isRead]: booleanSchema(fieldNames.isRead, t),
      });
};
