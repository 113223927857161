import { imageSchema, numberSchema, stringSchema, translationLaTiDeSchema } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  title: "title",
  description: "description",
  image: "image",
  date: "date",
  translations: "translations",
  listOrderNumber: "listOrderNumber",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.title]: stringSchema(fieldNames.title, t),
        [fieldNames.description]: stringSchema(fieldNames.description, t),
        [fieldNames.date]: stringSchema(fieldNames.date, t, "pleaseSelect"),
        [fieldNames.translations]: translationLaTiDeSchema(fieldNames.translations, t),
        [fieldNames.image]: imageSchema(fieldNames.image, t),
        [fieldNames.listOrderNumber]: numberSchema(fieldNames.listOrderNumber, t),
      })
    : yup.object().shape({
        [fieldNames.title]: stringSchema(fieldNames.title, t),
        [fieldNames.description]: stringSchema(fieldNames.description, t),
        [fieldNames.date]: stringSchema(fieldNames.date, t, "pleaseSelect"),
        [fieldNames.translations]: translationLaTiDeSchema(fieldNames.translations, t),
        [fieldNames.image]: imageSchema(fieldNames.image, t),
        [fieldNames.listOrderNumber]: numberSchema(fieldNames.listOrderNumber, t),
      });
};
