import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { truncateString } from "utils/others";

interface TableTruncateProps {
  text: string;
  withCopy?: boolean;
}

const TableTruncate: FC<TableTruncateProps> = ({ text, withCopy }) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = async () => {
    setIsCopied(true);
    try {
      await navigator.clipboard.writeText(text);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
      setIsCopied(false);
    }
  };
  return (
    <div
      className="group flex items-center justify-between cursor-pointer parent-animation-on-hover"
      onClick={handleCopy}
    >
      <span title={text}>{truncateString(text.replace("_", " "))}</span>
      {withCopy && (
        <FontAwesomeIcon
          icon={isCopied ? faCheck : faCopy}
          className="opacity-0 transition-all group-hover:opacity-100"
        />
      )}
    </div>
  );
};

export default TableTruncate;
