import Modal from "components/Modal";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Gallery from "./Gallery";
import UploadTab from "./uploader/Upload";
import GalleryMulti from "./GalleryMulti";
import Uploader from "./uploader/Uploader";

interface GalleryModalProps {
  showModal: boolean;
  handleModal: () => void;
  handleClickedImage: (image: any) => void;
  selectedPhoto?: any;
  isGalleryDisabled?: boolean;
  FOLDER_SLUG: string;
  editable?: boolean;
  isMulti?: boolean;
}

const GalleryModal: FC<GalleryModalProps> = ({
  showModal,
  handleModal,
  handleClickedImage,
  selectedPhoto,
  isGalleryDisabled = false,
  isMulti,
}) => {
  const [activeTab, setActiveTab] = useState(isGalleryDisabled ? "upload" : "gallery");
  const [disableGalleryFromUploader, setDisableGalleryFromUploader] = useState(false);
  const { t } = useTranslation();

  return (
    <Modal isOpen={showModal} onClose={handleModal} title={t("select_image")} size="super">
      {/* <div className="flex gap-2 mb-4 items-center">
        <button
          onClick={() => setActiveTab("gallery")}
          className={`px-6 py-2 -ml-4 ${activeTab === "gallery" ? "bg-primary text-white" : "bg-inputBg text-text"}`}
        >
          {t("gallery")}
        </button>
        <Uploader />
      </div> */}
      <div className="flex gap-2 mb-4 border-b border-b-primary">
        {!isGalleryDisabled && (
          <button
            type="button"
            onClick={disableGalleryFromUploader ? () => "" : () => setActiveTab("gallery")}
            className={`px-4 py-2 rounded-tl-md rounded-tr-md 
              ${activeTab === "gallery" ? "bg-primary text-white" : "bg-inputBg text-text"}
              ${disableGalleryFromUploader ? "cursor-not-allowed" : "cursor-pointer"}`}
          >
            {t("gallery")}
          </button>
        )}
        <button
          onClick={() => setActiveTab("upload")}
          type="button"
          className={`px-4 py-2 rounded-tl-md rounded-tr-md ${
            activeTab === "upload" ? "bg-primary text-white" : "bg-inputBg text-text"
          }`}
        >
          {t("upload")}
        </button>
      </div>
      {/* <div className="flex justify-between items-center">
        {activeTab === "gallery" &&
          !isGalleryDisabled &&
          (isMulti ? (
            <GalleryMulti
              handleClickedImage={handleClickedImage}
              selectedPhoto={selectedPhoto}
              handleModal={handleModal}
            />
          ) : (
            <Gallery
              handleClickedImage={handleClickedImage}
              selectedPhoto={selectedPhoto}
              handleModal={handleModal}
            />
          ))}
      </div> */}
      <div>
        {activeTab === "gallery" &&
          !isGalleryDisabled &&
          (isMulti ? (
            <GalleryMulti
              handleClickedImage={handleClickedImage}
              selectedPhoto={selectedPhoto}
              handleModal={handleModal}
            />
          ) : (
            <Gallery
              handleClickedImage={handleClickedImage}
              selectedPhoto={selectedPhoto}
              handleModal={handleModal}

              // editable={editable}
            />
          ))}
        {activeTab === "upload" && <Uploader {...{ setActiveTab, setDisableGalleryFromUploader }} />}
      </div>
    </Modal>
  );
};

export default GalleryModal;
