import * as Yup from "yup";
import { TFunction } from "i18next";

export const stringSchema = (fieldName: string, t: TFunction, errorText: string = "pleaseEnter") => {
  return Yup.string()
    .required(t(errorText, { changeablePart: t(fieldName) }))
    .label(t(fieldName));
};

export const booleanSchema = (fieldName: string, t: TFunction) => {
  return Yup.boolean().label(t(fieldName));
};

export const numberSchema = (fieldName: string, t: TFunction, errorText: string = "pleaseEnter") => {
  return Yup.number()
    .required(t(errorText, { changeablePart: t(fieldName) }))
    .label(t(fieldName));
};
export const arrayOfStrSchema = (fieldName: string, t: TFunction, errorText: string = "pleaseEnter") => {
  return Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string()
          .trim()
          .required(t("pleaseEnter", { changeablePart: t(fieldName) })),
      })
    )
    .label(t(fieldName))
    .required(t(errorText, { changeablePart: t(fieldName) }));
};

export const passwordSchema = (fieldName: string, t: TFunction) => {
  return Yup.string()
    .min(6, t("minCharacters"))
    .required(t("pleaseEnter", { changeablePart: t(fieldName) }))
    .label(t(fieldName));
};

export const confirmPasswordSchema = (fieldName: string, t: TFunction, passwordFieldName: string) => {
  return Yup.string()
    .oneOf([Yup.ref(passwordFieldName)], t("passwordMustMatch"))
    .label(fieldName);
};

export const objectSchema = (fieldName: string, t: TFunction) => {
  return Yup.object().required(t("pleaseSelect", { changeablePart: t(fieldName) }));
};

export const translationLaTiDeSchema = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        description: Yup.string().required(t("pleaseEnter", { changeablePart: t("description") })),
      })
      .required()
  );
};
export const translationProductsSchema = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        description: Yup.string().required(t("pleaseEnter", { changeablePart: t("description") })),
        shortDescription: Yup.string().required(t("pleaseEnter", { changeablePart: t("shortDescription") })),
        howToUse: Yup.string().required(t("pleaseEnter", { changeablePart: t("howToUse") })),
        drinks: drinksValidation(t),
      })
      .required()
  );
};
export const translationAcademyProgramSchema = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        details: detailsValidation(t),
      })
      .required()
  );
};
export const translationDescShortDesc = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        description: Yup.string().required(t("pleaseEnter", { changeablePart: t("description") })),
        homeShortDescription: Yup.string().required(t("pleaseEnter", { changeablePart: t("homeShortDescription") })),
      })
      .required()
  );
};
export const translationTiSub = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        subTitle: Yup.string().required(t("pleaseEnter", { changeablePart: t("subTitle") })),
      })
      .required()
  );
};
export const translationBrandText = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        homePageTitle: Yup.string().required(t("pleaseEnter", { changeablePart: t("homePageTitle") })),
      })
      .required()
  );
};
export const translationTitle = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
      })
      .required()
  );
};
export const translationDesc = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        description: Yup.string().required(t("pleaseEnter", { changeablePart: t("description") })),
      })
      .required()
  );
};
export const translationServices = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        subTitle: Yup.string().required(t("pleaseEnter", { changeablePart: t("subTitle") })),
        description: Yup.string().required(t("pleaseEnter", { changeablePart: t("description") })),
      })
      .required()
  );
};
export const branchesValidation = (t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        phone: Yup.string().required(t("pleaseEnter", { changeablePart: t("phone") })),
        address: Yup.string().required(t("pleaseEnter", { changeablePart: t("address") })),
        email: Yup.string()
          .email(t("pleaseEnterValid", { changeablePart: t("email") }))
          .required(t("pleaseEnter", { changeablePart: t("email") })),
      })
      .required()
  );
};
export const drinksValidation = (t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        subTitle: Yup.string().required(t("pleaseEnter", { changeablePart: t("subTitle") })),
      })
      .required()
  );
};
export const detailsValidation = (t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        description: Yup.string().required(t("pleaseEnter", { changeablePart: t("description") })),
      })
      .required()
  );
};
export const trialsDetailsValidation = (t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        crop: Yup.string().required(t("pleaseEnter", { changeablePart: t("crop") })),
        yieldInKerneltArea: Yup.number().required(t("pleaseEnter", { changeablePart: t("yieldInKerneltArea") })),
        yieldInUnusedArea: Yup.number().required(t("pleaseEnter", { changeablePart: t("yieldInUnusedArea") })),
        pdf: imageSchema(t("pdf"), t),
      })
      .required()
  );
};
export const trialsDetailsWithoutPDFValidation = (t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        crop: Yup.string().required(t("pleaseEnter", { changeablePart: t("crop") })),
        yieldInKerneltArea: Yup.number().required(t("pleaseEnter", { changeablePart: t("yieldInKerneltArea") })),
        yieldInUnusedArea: Yup.number().required(t("pleaseEnter", { changeablePart: t("yieldInUnusedArea") })),
      })
      .required()
  );
};

export const translationServiceText = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        homePageTitle: Yup.string().required(t("pleaseEnter", { changeablePart: t("homePageTitle") })),
        homePageShowAllTitle: Yup.string().required(t("pleaseEnter", { changeablePart: t("homePageShowAllTitle") })),
      })
      .required()
  );
};
export const translationAboutUs = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleasSelect", { changeablePart: t("language") })),
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        description: Yup.string().required(t("pleaseEnter", { changeablePart: t("description") })),
        subTitle: Yup.string().required(t("pleaseEnter", { changeablePart: t("subtitle") })),
        shortDescription: Yup.string().required(t("pleaseEnter", { changeablePart: t("shortDescription") })),
        buttonText: Yup.string().required(t("pleaseEnter", { changeablePart: t("buttonText") })),
      })
      .required()
  );
};
export const translationBanner = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        buttonTitle: Yup.string().required(t("pleaseEnter", { changeablePart: t("buttonTitle") })),
      })
      .required()
  );
};
export const translation4titles = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        title1: Yup.string().required(t("pleaseEnter", { changeablePart: t("title1") })),
        title2: Yup.string().required(t("pleaseEnter", { changeablePart: t("title2") })),
        title3: Yup.string().required(t("pleaseEnter", { changeablePart: t("title3") })),
        title4: Yup.string().required(t("pleaseEnter", { changeablePart: t("title4") })),
      })
      .required()
  );
};
export const translationProject = (fieldName: string, t: TFunction) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        language: Yup.object().required(t("pleaseSelect", { changeablePart: t("language") })),
        title: Yup.string().required(t("pleaseEnter", { changeablePart: t("title") })),
        description: Yup.string().required(t("pleaseEnter", { changeablePart: t("description") })),
        subTitle: Yup.string().required(t("pleaseEnter", { changeablePart: t("subtitle") })),
        tags: Yup.array()
          .of(
            Yup.object().shape({
              value: Yup.string().trim().required("Tag cannot be empty"),
            })
          )
          .label(t(fieldName)),
      })
      .required()
  );
};

export const imageSchema = (fieldName: string, t: TFunction) => {
  return Yup.lazy((value) => {
    if (value === undefined || value === null) {
      return Yup.mixed()
        .required(t("pleaseSelect", { changeablePart: t(fieldName) }))
        .label(fieldName);
    }
    switch (typeof value) {
      case "object":
        return Yup.object()
          .required(t("pleaseSelect", { changeablePart: t(fieldName) }))
          .label(fieldName); // schema for object
      case "string":
        return Yup.string()
          .required(t("pleaseSelect", { changeablePart: t(fieldName) }))
          .label(fieldName); // schema for string
      default:
        return Yup.mixed();
    }
  });
};

export const imageMultiSchema = (fieldName: string, t: TFunction) => {
  return Yup.lazy((value) => {
    if (value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
      return Yup.array()
        .of(Yup.mixed().required(t("pleaseSelect", { changeablePart: t(fieldName) })))
        .required(t("pleaseSelect", { changeablePart: t(fieldName) }))
        .label(fieldName);
    }
    if (Array.isArray(value)) {
      return Yup.array()
        .of(
          Yup.lazy((item) => {
            if (typeof item === "object") {
              return Yup.object()
                .required(t("pleaseSelect", { changeablePart: t(fieldName) }))
                .label(fieldName); // schema for object
            } else if (typeof item === "string") {
              return Yup.string()
                .required(t("pleaseSelect", { changeablePart: t(fieldName) }))
                .label(fieldName); // schema for string
            } else {
              return Yup.mixed();
            }
          })
        )
        .required(t("pleaseSelect", { changeablePart: t(fieldName) }))
        .label(fieldName);
    }
    return Yup.mixed();
  });
};
