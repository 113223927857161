import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

interface TableBooleanProps {
  value: boolean;
}

const TableBoolean: FC<TableBooleanProps> = ({ value }) => {
  return (
    <div>
      {value ? (
        <FontAwesomeIcon icon={faCheck} className="text-success" />
      ) : (
        <FontAwesomeIcon icon={faTimes} className="text-danger" />
      )}
    </div>
  );
};

export default TableBoolean;
