import TableDescTruncate from "components/table/renderComponents/TableDescTruncate";
import TableImage from "components/table/renderComponents/TableImage";
import TableTruncate from "components/table/renderComponents/TableTruncate";
import TableVideo from "components/table/renderComponents/TableVideo";
import { TableColumn } from "components/table/Table";
import { format } from "date-fns";

export interface Data {
  _id: string;
  homeShortDescription: string;
  description: string;
  buttonText: string;
}

export const columns: TableColumn<Data>[] = [
  {
    key: "_id",
    label: "id",
    sortable: true,
    render: (item: Data) => <TableTruncate text={item._id} withCopy />,
  },
  {
    key: "description",
    label: "description",
    sortable: true,
    render: (item: any) => <TableDescTruncate description={item?.description || ""} />,
  },
  {
    key: "homeShortDescription",
    label: "homeShortDescription",
    sortable: true,
    render: (item: any) => <TableDescTruncate description={item?.homeShortDescription || ""} />,
  },
  { key: "createdAt", label: "createdAt", sortable: true },
  // ----------------------- sample options with render -----------------------
  // {
  //   key: "address.city",
  //   label: "City",
  //   render: (item: any) => item.address.city, // Custom render for nested data
  // },
  // {
  //   key: "tags",
  //   label: "Tags",
  //   render: (item: any) =>
  //     item.tags.map((tag: string) => (
  //       <span key={tag} className="tag">
  //         {tag}
  //       </span>
  //     )),
  // },
];
