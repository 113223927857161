import { stringSchema, translationTitle } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  title: "title",
  translations: "translations",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.title]: stringSchema(fieldNames.title, t),

        [fieldNames.translations]: translationTitle(fieldNames.translations, t),
      })
    : yup.object().shape({
        [fieldNames.title]: stringSchema(fieldNames.title, t),

        [fieldNames.translations]: translationTitle(fieldNames.translations, t),
      });
};
