import {
    drinksValidation,
    imageSchema,
    numberSchema,
    objectSchema,
    stringSchema,
    translationLaTiDeSchema,
    translationProductsSchema,
} from 'components/inputs/validationSchema';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const fieldNames = {
    title: 'title',
    subTitle: 'subTitle',
    description: 'description',
    slug: 'slug',
    image: 'image',
    translations: 'translations',
    banner: 'banner',
    listOrderNumber: 'listOrderNumber',
    categoryId: 'categoryId',
    shortDescription: 'shortDescription',
    howToUse: 'howToUse',
    drinks: 'drinks',
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
    return URL_ID
        ? yup.object().shape({
              [fieldNames.title]: stringSchema(fieldNames.title, t),
              [fieldNames.description]: stringSchema(fieldNames.description, t),
              [fieldNames.slug]: stringSchema(fieldNames.slug, t),
              [fieldNames.translations]: translationProductsSchema(fieldNames.translations, t),
              [fieldNames.image]: imageSchema(fieldNames.image, t),
              [fieldNames.banner]: imageSchema(fieldNames.banner, t),
              [fieldNames.listOrderNumber]: numberSchema(fieldNames.listOrderNumber, t),
              [fieldNames.categoryId]: objectSchema(fieldNames.categoryId, t),
              [fieldNames.shortDescription]: stringSchema(fieldNames.shortDescription, t),
              [fieldNames.howToUse]: stringSchema(fieldNames.howToUse, t),
              [fieldNames.drinks]: drinksValidation(t),
          })
        : yup.object().shape({
              [fieldNames.title]: stringSchema(fieldNames.title, t),
              [fieldNames.description]: stringSchema(fieldNames.description, t),
              [fieldNames.slug]: stringSchema(fieldNames.slug, t),
              [fieldNames.translations]: translationProductsSchema(fieldNames.translations, t),
              [fieldNames.image]: imageSchema(fieldNames.image, t),
              [fieldNames.banner]: imageSchema(fieldNames.banner, t),
              [fieldNames.listOrderNumber]: numberSchema(fieldNames.listOrderNumber, t),
              [fieldNames.categoryId]: objectSchema(fieldNames.categoryId, t),
              [fieldNames.shortDescription]: stringSchema(fieldNames.shortDescription, t),
              [fieldNames.howToUse]: stringSchema(fieldNames.howToUse, t),
              [fieldNames.drinks]: drinksValidation(t),
          });
};
