import { arrayOfStrSchema, stringSchema, translationDesc } from "components/inputs/validationSchema";
import { TFunction } from "i18next";
import * as yup from "yup";

export const fieldNames = {
  email: "email",
  location: "location",
  linkedin: "linkedin",
  facebook: "facebook",
  instagram: "instagram",
  phones: "phones",
  telephones: "telephones",
} as const;

export const validation = (URL_ID: string | undefined, t: TFunction) => {
  return URL_ID
    ? yup.object().shape({
        [fieldNames.email]: stringSchema(fieldNames.email, t),
        [fieldNames.location]: stringSchema(fieldNames.location, t),
        [fieldNames.linkedin]: stringSchema(fieldNames.linkedin, t),
        [fieldNames.facebook]: stringSchema(fieldNames.facebook, t),
        [fieldNames.instagram]: stringSchema(fieldNames.instagram, t),
        [fieldNames.phones]: arrayOfStrSchema(fieldNames.phones, t),
        [fieldNames.telephones]: arrayOfStrSchema(fieldNames.telephones, t),
      })
    : yup.object().shape({
        [fieldNames.email]: stringSchema(fieldNames.email, t),
        [fieldNames.location]: stringSchema(fieldNames.location, t),
        [fieldNames.linkedin]: stringSchema(fieldNames.linkedin, t),
        [fieldNames.facebook]: stringSchema(fieldNames.facebook, t),
        [fieldNames.instagram]: stringSchema(fieldNames.instagram, t),
        [fieldNames.phones]: arrayOfStrSchema(fieldNames.phones, t),
        [fieldNames.telephones]: arrayOfStrSchema(fieldNames.telephones, t),
      });
};
