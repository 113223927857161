import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import Title from 'components/Title';
import CardLayout from 'components/form/CardLayout';
import FormLayout from 'components/form/FormLayout';
import TwoSideLayout from 'components/form/TwoSideLayout';
import GalleryInput from 'components/gallery/GalleryInput';
import GalleryModal from 'components/gallery/GalleryModal';
import TextEditorInput from 'components/inputs/TextEditorInput';
import TextInput from 'components/inputs/TextInput';
import TranslationServiceInput from 'components/inputs/TranslationServiceInput';
import apiEndpoints from 'configs/apiEndPoints';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import toastFunc from 'utils/toast';
import { useMutationCustom, useQueryCustom } from 'utils/useQueryCUstom';
import { CONFIGS } from '../configs';
import { fieldNames, validation } from './validation';
import GalleryInputMulti from 'components/gallery/GalleryInputMulti';
import TranslationInput from 'components/inputs/TranslationInput';
import SelectInput from 'components/inputs/SelectInput';
import DrinksInput from 'components/inputs/DrinksInput';
import TranslationProductsInput from 'components/inputs/TranslationProductsInput';
import SelectInputAsync from 'components/inputs/SlectInputAsync';

interface Translation {
    title: string;
    subTitle?: string;
    description: string;
    shortDescription: string;
    howToUse: string;
    drinks?: any;
    language: any;
}

interface FormValues {
    title: string;
    subTitle?: string;
    description: string;
    slug: string;
    image?: string | any;
    banner?: string | any;
    listOrderNumber: number;
    translations?: Translation[];
    categoryId: any;
    drinks?: any;
    shortDescription: string;
    howToUse: string;
}

const Add: FC = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const URL_ID = id ? id : '';
    const [editInitialLoading, setEditInitialLoading] = useState(id ? true : false);
    const { t } = useTranslation();
    const updateOrCreate = URL_ID
        ? (params: any) => axios.put(apiEndpoints[CONFIGS.apiName].base + '/' + URL_ID, params)
        : (params: any) => axios.post(apiEndpoints[CONFIGS.apiName].base, params);

    const methods = useForm<FormValues>({
        resolver: yupResolver(validation(URL_ID, t)),
    });

    // --------------------------------------------------- data by id query
    const onSuccessDataById = ({ data }: any) => {
        let params = { ...data?.data };
        handleSelectImageThumbnail(params?.image);
        handleSelectBannerThumbnail(params?.banner);
        const convertedTranslation = params?.translations?.map((item: any) => ({
            ...item,
            language: { value: item.language.code, label: item.language.name },
        }));
        const convertCategory = { value: params?.category?._id, label: params?.category?.title };
        methods.reset({
            ...params,
            translations: convertedTranslation,
            categoryId: convertCategory,
        });
        setEditInitialLoading(false);
    };
    const { data: dataById } = useQueryCustom({
        name: `${CONFIGS.title}_getById`,
        url: () => axios.get(apiEndpoints[CONFIGS.apiName].base + '/' + URL_ID),
        onSuccess: onSuccessDataById,
        enabled: !!URL_ID,
    });
    // --------------------------------------------------- Create or update mutation (base on URL_ID)
    const { isLoading, mutate: submitData } = useMutationCustom({
        url: updateOrCreate,
        invalidQuery: `${CONFIGS.title}_get`,
        onSuccess: () => {
            toastFunc.success({ title: 'Success' });
            navigate(CONFIGS.routes.base);
        },
    });

    // -------------------------------------------- onSubmit
    const onSubmit = (data: FormValues) => {
        const convertedTranslation = data?.translations?.map((item) => ({
            ...item,
            language: item.language.value,
        }));

        const requestData = {
            ...data,
            image: data?.image?.path ? data?.image?.path : imageThumbnail,
            banner: data?.banner?.path ? data?.banner?.path : bannerThumbnail,
            translations: convertedTranslation,
            categoryId: data?.categoryId.value,
        };
        console.log(requestData);
        submitData(requestData);
    };

    // -------------------------------- Image ----------------------------------
    const [showImageModal, setShowImageModal] = useState(false);
    const [imageThumbnail, setImageThumbnail] = useState<
        | {
              mimetype: string;
              src: string;
          }
        | string
        | null
    >(null);

    const handleImageModal = () => {
        setShowImageModal(!showImageModal);
    };

    const handleSelectImageThumbnail = (thumbnail: { mimetype: string; src: string } | string) => {
        setImageThumbnail(thumbnail);
        methods.setValue(fieldNames.image, thumbnail);
        methods.clearErrors(fieldNames.image);
    };
    // -------------------------------- banner ----------------------------------
    const [showBannerModal, setShowBannerModal] = useState(false);
    const [bannerThumbnail, setBannerThumbnail] = useState<
        | {
              mimetype: string;
              src: string;
          }
        | string
        | null
    >(null);

    const handleBannerModal = () => {
        setShowBannerModal(!showBannerModal);
    };

    const handleSelectBannerThumbnail = (thumbnail: { mimetype: string; src: string } | string) => {
        setBannerThumbnail(thumbnail);
        methods.setValue(fieldNames.banner, thumbnail);
        methods.clearErrors(fieldNames.banner);
    };

    return (
        <div>
            {editInitialLoading && (
                <Modal isOpen={true} title={t('loadingData')}>
                    <div className="flex justify-center items-center">
                        <Loading size="w-8 h-8" />
                    </div>
                </Modal>
            )}
            <Title
                hasBtn
                btnTitle={t('backTitle', { changeablePart: t(CONFIGS.title) })}
                title={
                    URL_ID
                        ? t('editTitle', { changeablePart: t(CONFIGS.title) })
                        : t('addNew', { changeablePart: t(CONFIGS.title) })
                }
                url={CONFIGS.routes.base + CONFIGS.routes.list}
            />

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4">
                    <FormLayout
                        main={
                            <>
                                <CardLayout>
                                    <TwoSideLayout
                                        left={
                                            <>
                                                <TextInput
                                                    isRequired
                                                    name={fieldNames.title}
                                                    placeholder={t('enterYourHere', {
                                                        changeablePart: t(fieldNames.title),
                                                    })}
                                                    label={fieldNames.title}
                                                    size="full"
                                                />
                                                <TextInput
                                                    isRequired={false}
                                                    name={fieldNames.subTitle}
                                                    placeholder={t('enterYourHere', {
                                                        changeablePart: t(fieldNames.subTitle),
                                                    })}
                                                    label={fieldNames.subTitle}
                                                    size="full"
                                                />
                                                <SelectInputAsync
                                                    name={fieldNames.categoryId}
                                                    apiName="products-category"
                                                    label={fieldNames.categoryId}
                                                    isRequired
                                                />
                                                <TextEditorInput
                                                    isRequired
                                                    name={fieldNames.description}
                                                    placeholder={t('enterYourHere', {
                                                        changeablePart: t(fieldNames.description),
                                                    })}
                                                    label={fieldNames.description}
                                                    size="full"
                                                />
                                                <TextInput
                                                    isRequired
                                                    name={fieldNames.slug}
                                                    placeholder={t('enterYourHere', {
                                                        changeablePart: t(fieldNames.slug),
                                                    })}
                                                    label={fieldNames.slug}
                                                    size="full"
                                                />
                                                <TextInput
                                                    isRequired
                                                    name={fieldNames.shortDescription}
                                                    placeholder={t('enterYourHere', {
                                                        changeablePart: t(fieldNames.shortDescription),
                                                    })}
                                                    label={fieldNames.shortDescription}
                                                    size="full"
                                                />
                                                <TextEditorInput
                                                    isRequired
                                                    name={fieldNames.howToUse}
                                                    placeholder={t('enterYourHere', {
                                                        changeablePart: t(fieldNames.howToUse),
                                                    })}
                                                    label={fieldNames.howToUse}
                                                    size="full"
                                                />
                                                <TextInput
                                                    isRequired
                                                    name={fieldNames.listOrderNumber}
                                                    placeholder={t('enterYourHere', {
                                                        changeablePart: t(fieldNames.listOrderNumber),
                                                    })}
                                                    label={fieldNames.listOrderNumber}
                                                    size="full"
                                                    type="number"
                                                />
                                            </>
                                        }
                                        right={
                                            <>
                                                <GalleryInput
                                                    isRequired
                                                    handleModal={handleImageModal}
                                                    selectedPhoto={imageThumbnail}
                                                    label={fieldNames.image}
                                                    name={fieldNames.image}
                                                    size="full"
                                                />
                                                <GalleryInput
                                                    isRequired
                                                    handleModal={handleBannerModal}
                                                    selectedPhoto={bannerThumbnail}
                                                    label={fieldNames.banner}
                                                    name={fieldNames.banner}
                                                    size="full"
                                                />
                                            </>
                                        }
                                    />
                                </CardLayout>
                                <CardLayout head={{ noTitle: true }}>
                                    <DrinksInput name={fieldNames.drinks} />
                                </CardLayout>
                                <CardLayout head={{ noTitle: true }}>
                                    <TranslationProductsInput name={fieldNames.translations} />
                                </CardLayout>
                            </>
                        }
                        side={
                            <CardLayout head={{ title: 'action' }} className="sticky top-4">
                                <Button type="submit" variant="success" isFullSize loading={isLoading}>
                                    {t('submit')}
                                </Button>
                            </CardLayout>
                        }
                    />
                </form>
            </FormProvider>
            <GalleryModal
                showModal={showImageModal}
                handleModal={handleImageModal}
                handleClickedImage={handleSelectImageThumbnail}
                selectedPhoto={imageThumbnail}
                FOLDER_SLUG="your-folder-slug"
            />
            <GalleryModal
                showModal={showBannerModal}
                handleModal={handleBannerModal}
                handleClickedImage={handleSelectBannerThumbnail}
                selectedPhoto={bannerThumbnail}
                FOLDER_SLUG="your-folder-slug"
            />
        </div>
    );
};

export default Add;
