import { FC } from "react";

interface TableVideoProps {
  video: string;
}

const TableVideo: FC<TableVideoProps> = ({ video }) => {
  return <video src={video} className="w-20 h-12 rounded-md object-cover object-top" />;
};

export default TableVideo;
