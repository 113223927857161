import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import SelectInputAsync from "./SlectInputAsync";
import TextInput from "./TextInput";
import { useTranslation } from "react-i18next";
import TextEditorInput from "./TextEditorInput";

const TranslationDescShortDescInput: React.FC<{ name: string }> = ({ name }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });
  const { t } = useTranslation();

  return (
    <form className="p-4">
      <div className="flex justify-between items-center w-full mb-1 parent-animation-on-hover">
        <h2 className="text-lg font-semibold text-text">{t("translations")}</h2>
        <FontAwesomeIcon
          icon={faPlus}
          className="squish-on-hover cursor-pointer"
          title="add"
          onClick={() => append({ language: "", description: "", homeShortDescription: "" })}
        />
      </div>
      {fields.length
        ? fields.map((field, index) => (
            <div key={field.id} className="py-4 border-b border-border">
              <div className="flex justify-between items-center w-full mb-2 parent-animation-on-hover">
                <h3 className="text-md">{t("translationIndex", { changeablePart: index + 1 })}</h3>
                <FontAwesomeIcon
                  title="Remove"
                  icon={faMinus}
                  className="squish-on-hover cursor-pointer text-danger"
                  onClick={() => remove(index)}
                />
              </div>
              <SelectInputAsync
                name={`translations.${index}.language`}
                apiName="languages"
                // label="Language"
                isRequired
              />
              {(errors?.translations as any)?.[index]?.language && (
                <p className="text-red-500 text-sm">
                  {String((errors?.translations as any)?.[index]?.language?.message)}
                </p>
              )}

              <TextEditorInput
                isRequired
                name={`translations.${index}.description`}
                placeholder={t("enterYourHere", { changeablePart: t("description") })}
                size="full"
              />
              <TextEditorInput
                name={`translations.${index}.homeShortDescription`}
                placeholder={t("enterYourHere", { changeablePart: t("homeShortDescription") })}
                size="full"
                isRequired
              />
            </div>
          ))
        : ""}
    </form>
  );
};

export default TranslationDescShortDescInput;
